import React from 'react';
import "./Styles/FamilyRoute.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
import FamilyForm from './Components/FamilyRoute/FamilyForm';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import FamilyRouteTitle from './Components/FamilyRoute/FamilyTitle';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from './Components/Context/MedicalContext';

function FamilyRoute() {
    return (
        <UserInfoProvider>
            <MedicalContextProvider>
            <div className="FamilyRoutePage">
                <div className="FamilyRouteNavBar">      
                        <NavBar/>
                     </div>
                <div className="FamilyRouteTitle">
                    <FamilyRouteTitle/>    
                </div>
                <div className="FamilyRouteForm">
                    <FamilyForm/>
                </div>
                <div className="FamilyRouteFooter">
                    <Footer/>
                </div>
            </div>
</MedicalContextProvider>
       </UserInfoProvider>
    );
}

export default FamilyRoute;