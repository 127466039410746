import { useState, createContext, useEffect } from "react";

export const LogContext = createContext();


const LogContextProvider = (props) => {

  //const [catch, seterrorcatch] = useState()

  const [UserData, setUserData] = useState(() => {
    const localData = localStorage.getItem("UserData");
    return localData ? JSON.parse(localData) : [];
  });
  const [User, setUser] = useState(() => {
    const localData = localStorage.getItem("User");
    return localData ? JSON.parse(localData) : [];
  });
  const [Pasword, setPasword] = useState(() => {
    const localData = localStorage.getItem("Pasword");
    return localData ? JSON.parse(localData) : [];
  });

  const [tokenview, settokenview] = useState(() => {
    const localData = localStorage.getItem("tokenview");
    return localData ? JSON.parse(localData) : null;
  });

  useEffect(() => {
    localStorage.setItem("User", JSON.stringify(User));
  }, [User]);
  useEffect(() => {
    localStorage.setItem("Pasword", JSON.stringify(Pasword));
  }, [Pasword]);
  useEffect(() => {
    localStorage.setItem("tokenview", JSON.stringify(tokenview));
  }, [tokenview]);
  const PaswordUser = ["demo2023"];

  useEffect(() => {
    localStorage.setItem("PaswordUser", JSON.stringify(PaswordUser));
  }, [PaswordUser]);

  useEffect(() => {
    localStorage.setItem("UserData", JSON.stringify(UserData));
  }, [UserData]);

  const [validador, setvalidador] = useState(() => {
    const localData = localStorage.getItem("validador");
    return localData ? JSON.parse(localData) : false;
  });

  const [error, seterrorr] = useState(() => {
    const localData = localStorage.getItem("error");
    return localData ? JSON.parse(localData) : true;
  });




  // useEffect(() => {
  //   fetch('http://localhost:4000/users', {
  //     'method': 'GET',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   })
  //     .then(resp => resp.json())
  //     .then(resp => {
  //       const filtered = resp.filter(UserData => UserData.code === User);
  //       setUserData(filtered)
  //     })
  //     .catch(error => console.log(error))
  // }, []);


  const loguot = () =>{
    sessionStorage.removeItem("token")
    settokenview(null)
    setUserData([])
  }

  const  handeltoken = async(User,password) => {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rut: User,
        password: password
      })
    };
    try{

 
    const resp = await fetch("https://flowapiymc.azurewebsites.net/users", opts)
     if (resp.status !== 200){
      alert("there has been some error");
      return false;
     }

     const data = await resp.json();
     sessionStorage.setItem("token",data.access_token)
     settokenview(data.access_token)
     return true
    }
    catch(error){
      console.log("there has been an error login in")
    }
  };


  const  Usergrabdata = async(User,password) => {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rut: User,
        password: password
      })
    };
    try{

 
    const resp = await fetch("https://flowapiymc.azurewebsites.net/login", opts)
     if (resp.status !== 200){
      alert("there has been some error");
      return false;
     }
     const data = await resp.json();
     setUserData(data)
     return true
    }
    catch(error){
      console.log("there has been an error login in")
    }
  };



  /*
    useEffect(() => {
      localStorage.setItem("UserAuth", JSON.stringify(UserAuth));
    }, [UserAuth]);
  
  
    useEffect(() => {
      localStorage.setItem("PaswordAuth", JSON.stringify(PaswordAuth));
    }, [PaswordAuth]);
  
    
      useEffect(() => {
        localStorage.setItem("PaswordUserAuth", JSON.stringify(PaswordAuthUserAuth));
      }, [PaswordAuthUserAuth]);
      useEffect(() => {
        localStorage.setItem("PaswordMedicAuth", JSON.stringify(PaswordAuthMedic));
      }, [PaswordAuthMedic]);
      useEffect(() => {
        localStorage.setItem("PaswordToma", JSON.stringify(PaswordAuthToma));
      }, [PaswordAuthToma]);
  
      useEffect(() => {
        localStorage.setItem("RutdUserAuth", JSON.stringify(RutdUserAuth));
      }, [RutdUserAuth]);
      useEffect(() => {
        localStorage.setItem("RutdMedic", JSON.stringify(RutdMedic));
      }, [RutdMedic]);
      useEffect(() => {
        localStorage.setItem("RutdToma", JSON.stringify(RutdToma));
      }, [RutdToma]); */

  useEffect(() => {
    localStorage.setItem("validador", JSON.stringify(validador));
  }, [validador]);

  useEffect(() => {
    localStorage.setItem("error", JSON.stringify(error));
  }, [error]);


  return (
    <LogContext.Provider value={{
      validador, setvalidador, error, seterrorr,User, setUser,Pasword, setPasword,PaswordUser,handeltoken,tokenview,loguot,UserData,setUserData,Usergrabdata
    }}>
      {props.children}
    </LogContext.Provider>
  );
};
export default LogContextProvider;






// const handeltoken = (User,Pasword) => {
//   const opts = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       rut: User,
//       password: Pasword
//     })
//   };

//   fetch("http://localhost:4000/token", opts)
//     .then(resp => {
//       if (resp.status === 200) return resp.json();
//       else alert("there has been some error");
//     })
//     .then(data=>{
//       console.log("this came from backend",data);
//       sessionStorage.setItem("token",data.access_token)
//     })
//     .catch((error) => {
//       console.error("there is a error!!!", error);
//     });
// };