import React, { useState, useEffect, useContext } from "react";
import "../../Styles/OrderConfirmedCreator.css";
import { MedicalContext } from "../../Components/Context/MedicalContext";
import YMC_Logo_WText_Slogan from "../../Photos/YMC_Logo_WText_Slogan.png"
import TinyExamIcon from "../../Photos/TinyExamIcon.png"
import FirmaOficial from "../../Photos/firma.png"
import PhoneIcon from "../../Photos/PhoneIcon.png"
import EmailIcon from "../../Photos/EmailIcon.png"
import { LogContext } from "../../Components/Context/LoginContext";
import { BasketContext } from '../Context/BasketContext';
import { Packs } from '../Context/PacksContext';
import ConfettiExplosion from "react-confetti-explosion";
import { UserInfo } from "../Context/UserInfoContext";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Modal } from 'flowbite-react';
import examslist from "../../../JSONS/updatedExams.json"
import { useNavigate } from 'react-router-dom';

const OrderConfirmedCreator = () => {

  const Navigate = useNavigate();

  const {
    Basket, setBasket, ExamsForOrder, setExamsForOrder, OrderExamsCodes, Discounted
  } = useContext(BasketContext);


  const {
    Name, Rut, DoB, MedHis, FamilyMedHis, Gender, Age, Email, Phone
  } = useContext(UserInfo);

  const {
    Purchased, Added, SetAdded, ExamediSelected, BasketPacks, ExamsPerProduct
  } = useContext(Packs);

  const {
    RelatedExams
  } = useContext(MedicalContext);

  const [Loading, setLoading] = useState(false);
  const [LoadingImages, setLoadingImages] = useState(false);
  const [LoadingImage1, setLoadingImage1] = useState(false);
  const [LoadingImage2, setLoadingImage2] = useState(false);

  useEffect(() => {
    if(localStorage.getItem("OrderExamsCodes") === null || Object.keys(OrderExamsCodes).length === 0 || Rut.length === 0 || Name.length === 0 || parseInt(Age) === 0 || Purchased === false) {
      Navigate("/orden-medica")
    } else {
      if(!Added) {
        uploadOrder()
        SetAdded(true)
      }
    }
  })

  useEffect(() => {
    if(LoadingImage1 && LoadingImage2) {
      setLoadingImages(false)
      setLoadingImage1(false)
      setLoadingImage2(false)
    }
  })

  //const UserAge = new Date().getFullYear() - new Date(DoB).getFullYear();

  const DateMedical = new Date();
  const DateFormatted = (new Intl.DateTimeFormat(['ban', 'id']).format(DateMedical)).toString()
  const month = DateMedical.getMonth() + 1;

  const [openModal, setOpenModal] = useState(false);
  const [orderChosen, setOrderChosen] = useState(false);
  const [HowMany, setHowMany] = useState(0);

  function PDFstuff() {
    const pdf = new jsPDF();

    let manyExams = 0
    if(orderChosen === "Imagenología y Procedimientos") {
      manyExams = OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length
    } else {
      manyExams = OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen).length
    }

    if(manyExams > 26) {
      Promise.all([
        html2canvas(document.getElementById('myPage'), { quality: 0.8 }),
        html2canvas(document.getElementById('myPage2'), { quality: 0.8 })
      ])
      .then(function([blob1,  blob2]) {
        let imgProps= pdf.getImageProperties(blob1);
        let pdfWidth = pdf.internal.pageSize.getWidth();
        let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(blob1, 'PNG', 0, 0,pdfWidth, pdfHeight);

        pdf.addPage()
        imgProps= pdf.getImageProperties(blob2);
        pdfWidth = pdf.internal.pageSize.getWidth();
        pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(blob2, 'PNG', 0, 0,pdfWidth, pdfHeight);
        pdf.save("Orden "+orderChosen+".pdf"); 
      });
    } else {
      html2canvas(document.getElementById('myPage'), { quality: 0.8 })
          .then(function (dataUrl) {
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dataUrl, 0, 0,pdfWidth, pdfHeight);

            pdf.save("Orden "+orderChosen+".pdf"); 
          });
        }
  }

  async function uploadOrder() {
    setLoading(true)

    let payment = 0
    for(let i = 0; i<BasketPacks.length; i++) {
      payment += ExamsPerProduct[BasketPacks[i]].price
    }
  
    if(RelatedExams.length !== 0) {
      payment += 2990
    }

    payment = payment * (100 - Discounted) / 100

    var params = {
      name: Name,
      rut: Rut,
      gender: Gender,
      email: Email,
      age: Age,
      exams: OrderExamsCodes,
      phone: Phone,
      examediExams: ExamediSelected ? OrderExamsCodes.map((e) => examslist[e].name) : null,
      discount: Discounted,
      price: payment
    };
    
    var requestOptions = {
        method: 'POST',
        redirect: 'follow',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    };
    
    try{
        
        const resp = await fetch("https://ymc-doc-api.azurewebsites.net/addanonymous", requestOptions)
        if (resp.status !== 200){
            alert("there has been some error");
            return false;
        }
        const data = await resp.json();
        if(data.acknowledged) {
            setLoading(false)
        } else {
            setLoading(false)
            return false
        }
    } catch(error){
        setLoading(false)
        return false
    }
}
    

  return (
    <>
    {Object.keys(OrderExamsCodes).length === 0 || OrderExamsCodes === null || Loading ?
      <div className="OrderConfirmedCreator">
        <h1 className="ThankYouMessage mt-5">
          Cargando Orden Médica
        </h1>
        <div className="OrderConfirmedCreatorSpinner"></div>
      </div>
    :
      <div className="OrderConfirmedCreator">
        <h1 className="ThankYouMessage mt-5">
          Tu orden de exámenes ha sido creada con éxito
        </h1>
        <h3 className="ThankYouMessageSub m-0 mb-5">
          Puedes descargar las órdenes con los botones a continuación
        </h3>
        <ConfettiExplosion className="Confetti" />
        <div className='OrderConfirmedPreviewButtons'>
          {OrderExamsCodes.filter((Item) => examslist[Item].emission === 'Laboratorio').length > 0 ?
          <button onClick={() => {setOrderChosen('Laboratorio'); setOpenModal(true); setLoadingImages(true); setHowMany(OrderExamsCodes.filter((Item) => examslist[Item].emission === 'Laboratorio').length);}} className='OrderConfirmedPreviewButton rounded-pill'>Ver Orden de Exámenes <br/> Laboratorio</button>
          : null }
          {OrderExamsCodes.filter((Item) => examslist[Item].emission === 'Imagenología' | examslist[Item].emission === 'Procedimientos').length > 0 ?
          <button onClick={() => {setOrderChosen('Imagenología y Procedimientos'); setOpenModal(true); setLoadingImages(true); setHowMany(OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length);}} className='OrderConfirmedPreviewButton rounded-pill'>Ver Orden de Exámenes <br/> Imagenología / Procedimientos</button>
          : null }
          {OrderExamsCodes.filter((Item) => examslist[Item].emission === 'Derivaciones').length > 0 ?
          <button onClick={() => {setOrderChosen('Derivaciones'); setOpenModal(true); setLoadingImages(true); setHowMany(OrderExamsCodes.filter((Item) => examslist[Item].emission === 'Derivaciones').length);}} className='OrderConfirmedPreviewButton rounded-pill'>Ver Orden de Exámenes <br/> Derivaciones</button>
          : null }
        </div>
        <Modal dismissible show={openModal} position="center" className='OrderConfirmedPreviewModal'>
            <Modal.Body className='OrderConfirmedPreviewModalBody'>
              <div className='OrderConfirmedPreviewModalHeaderTitle'>Preview Orden Médica {orderChosen}</div>
              <div className='OrderConfirmedPreviewModalHeaderButtons'>
                {LoadingImages ? 
                <div className="OrderConfirmedPreviewModalDownloadButton">Cargando...</div>
                :
                <div className="OrderConfirmedPreviewModalDownloadButton" onClick={() => PDFstuff()}>DESCARGAR</div>
}
                <div className="OrderConfirmedPreviewModalCloseButton" onClick={() => setOpenModal(false)}>CERRAR</div>
              </div>
              <div className="OrderConfirmedPreviewModalPDFContainer">
                <div id="myPage" className="OrderConfirmedPreviewModalPDF">
                  <div className="OrderConfirmedPreviewModalPDFUpperRow">
                    <div className="OrderConfirmedPreviewModalPDFUpperLeftSide">
                      <img className="OrderConfirmedPreviewModalPDFUpperLeftLogo" alt='' src={YMC_Logo_WText_Slogan} onLoad={() => setLoadingImage1(true)}/>
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFUpperRightTexts">
                      <div className="OrderConfirmedPreviewModalPDFUpperUpperRightOrderText">Orden de {orderChosen}</div>
                      <div className="OrderConfirmedPreviewModalPDFUpperUpperRightText1">Fecha de emisión</div>
                      <div className="OrderConfirmedPreviewModalPDFUpperUpperRightText2">{DateFormatted}</div>
                    </div>
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFPatientData">
                    <span className="OrderConfirmedPreviewModalPDFPatientDataTitle">Paciente</span>
                    <div className="OrderConfirmedPreviewModalPDFPatientDataName">
                      Nombre del paciente: {Name}
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFPatientDataSecondRow">
                      <div className="OrderConfirmedPreviewModalPDFPatientDataRut">
                        Rut: {Rut}
                      </div>
                      <div className="OrderConfirmedPreviewModalPDFPatientDataBorn">
                        Edad: {Age}
                      </div>
                      <div className="OrderConfirmedPreviewModalPDFPatientDataSex">
                        Sexo: {Gender === 1 ? 'Hombre' : 'Mujer'}
                      </div>
                    </div>
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFExamsData">
                  {orderChosen === "Imagenología y Procedimientos" ?
                  <>
                    <div className="OrderConfirmedPreviewModalPDFExamsDataUpperFlexDiv">
                      <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">Exámenes</span>
                      <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">({OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length})</span>
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFExamsDataLowerFlexDiv">
                    {[...Array(OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length).keys()].map((object) => {
return (
                    <div className="OrderConfirmedPreviewModalPDFExamsDataOneExam" key={object}>
                    {object < 26 ?
                    <div>
                      <div className="OrderConfirmedPreviewModalPDFExamsDataExamName">
                        <img className="OrderConfirmedPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                        &nbsp; {examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].name}</div>
                      <div className="OrderConfirmedPreviewModalPDFExamsDataExamPreparation">{examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].prep} </div>
                    </div>
                    : null}
                    </div>
)})}
                  </div>
                  </>
                    :
                    <>
                    <div className="OrderConfirmedPreviewModalPDFExamsDataUpperFlexDiv">
                      <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">{orderChosen === "Derivaciones" ? "Derivaciones" : "Exámenes"}</span>
                      <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">({OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen).length})</span>
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFExamsDataLowerFlexDiv">
                      {[...Array(OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen).length).keys()].map((object) => {
  return (
                      <div className="OrderConfirmedPreviewModalPDFExamsDataOneExam" key={object}>
                      {object < 26 ?
                      <div>
                        <div className="OrderConfirmedPreviewModalPDFExamsDataExamName">
                          <img className="OrderConfirmedPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                          &nbsp; {examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen)[object]].name}</div>
                        <div className="OrderConfirmedPreviewModalPDFExamsDataExamPreparation">{examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen)[object]].prep} </div>
                      </div>
                      : null}
                      </div>
  )})}
                    </div>
                    </>
}
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFSignatureDiv">
                    <img className="OrderConfirmedPreviewModalPDFSignatureImage" alt='' src={FirmaOficial} onLoad={() => setLoadingImage2(true)}/>
                    <div className="OrderConfirmedPreviewModalPDFDoctorName" >Jorge Caro Díaz</div>
                    <div className="OrderConfirmedPreviewModalPDFDoctorName" >Médico-Cirujano</div>
                    <div className="OrderConfirmedPreviewModalPDFDoctorName" >RUT: 15.946.308-7&emsp;RCM: 27978-1</div>
                    
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFContactDiv">
                    <div className="OrderConfirmedPreviewModalPDFContactText">En caso de dudas o consultas, contáctanos</div>
                    <div className="OrderConfirmedPreviewModalPDFContactFlex">
                      <div className="OrderConfirmedPreviewModalPDFContactLeft">
                        <img className="OrderConfirmedPreviewModalPDFContactIcon" alt='' src={PhoneIcon} />
                        <span className="OrderConfirmedPreviewModalPDFContactLowerText"> &nbsp; +56 9 7127 3898 </span>
                      </div>
                      <div className="OrderConfirmedPreviewModalPDFContactRight">
                        <img className="OrderConfirmedPreviewModalPDFContactIcon" alt='' src={EmailIcon} />
                        <span className="OrderConfirmedPreviewModalPDFContactLowerText"> &nbsp; contacto@yomecontrolo.cl </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {HowMany > 26 ? 
              <div className="OrderConfirmedPreviewModalPDFContainer">
              <div id="myPage2" className="OrderConfirmedPreviewModalPDF">
                <div className="OrderConfirmedPreviewModalPDFUpperRow">
                  <div className="OrderConfirmedPreviewModalPDFUpperLeftSide">
                    <img className="OrderConfirmedPreviewModalPDFUpperLeftLogo" alt='' src={YMC_Logo_WText_Slogan} />
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFUpperRightTexts">
                    <div className="OrderConfirmedPreviewModalPDFUpperUpperRightOrderText">Orden de {orderChosen}</div>
                    <div className="OrderConfirmedPreviewModalPDFUpperUpperRightText1">Fecha de emisión</div>
                    <div className="OrderConfirmedPreviewModalPDFUpperUpperRightText2">24/3/2024</div>
                  </div>
                </div>
                <div className="OrderConfirmedPreviewModalPDFPatientData">
                  <span className="OrderConfirmedPreviewModalPDFPatientDataTitle">Paciente</span>
                  <div className="OrderConfirmedPreviewModalPDFPatientDataName">
                    Nombre del paciente: {Name}
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFPatientDataSecondRow">
                    <div className="OrderConfirmedPreviewModalPDFPatientDataRut">
                      Rut: {Rut}
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFPatientDataBorn">
                      Edad: {Age}
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFPatientDataSex">
                      Sexo: {Gender === 1 ? 'Hombre' : 'Mujer'}
                    </div>
                  </div>
                </div>
                <div className="OrderConfirmedPreviewModalPDFExamsData">
                {orderChosen === "Imagenología y Procedimientos" ?
                  <>
                    <div className="OrderConfirmedPreviewModalPDFExamsDataUpperFlexDiv">
                      <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">Exámenes</span>
                      <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">({OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length})</span>
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFExamsDataLowerFlexDiv">
                    {[...Array(OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos").length).keys()].map((object) => {
return (
                    <div className="OrderConfirmedPreviewModalPDFExamsDataOneExam" key={object}>
                    {object > 25 ?
                    <div>
                      <div className="OrderConfirmedPreviewModalPDFExamsDataExamName">
                        <img className="OrderConfirmedPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                        &nbsp; {examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].name}</div>
                      <div className="OrderConfirmedPreviewModalPDFExamsDataExamPreparation">{examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === "Imagenología" | examslist[Item].emission === "Procedimientos")[object]].prep} </div>
                    </div>
                    : null}
                    </div>
)})}
                  </div>
                  </>
                    :
                    <>
                  <div className="OrderConfirmedPreviewModalPDFExamsDataUpperFlexDiv">
                    <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">{orderChosen === "Derivaciones" ? "Derivaciones" : "Exámenes"}</span>
                    <span className="OrderConfirmedPreviewModalPDFExamsDataTitle">({OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen).length})</span>
                  </div>
                  <div className="OrderConfirmedPreviewModalPDFExamsDataLowerFlexDiv">
                    {[...Array(OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen).length).keys()].map((object) => {
return (
                    <div className="OrderConfirmedPreviewModalPDFExamsDataOneExam" key={object}>
                    {object > 25 ?
                    <div>
                      <div className="OrderConfirmedPreviewModalPDFExamsDataExamName">
                        <img className="OrderConfirmedPreviewModalPDFExamsDataExamIcon" alt='' src={TinyExamIcon} />
                        &nbsp; {examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen)[object]].name}</div>
                      <div className="OrderConfirmedPreviewModalPDFExamsDataExamPreparation">{examslist[OrderExamsCodes.filter((Item) => examslist[Item].emission === orderChosen)[object]].prep} </div>
                    </div>
                    : null}
                    </div>
)})}
                  </div>
                  </>
}
                </div>
                <div className="OrderConfirmedPreviewModalPDFSignatureDiv">
                  <img className="OrderConfirmedPreviewModalPDFSignatureImage" alt='' src={FirmaOficial}/>
                  <div className="OrderConfirmedPreviewModalPDFDoctorName" >Jorge Caro Díaz</div>
                  <div className="OrderConfirmedPreviewModalPDFDoctorName" >Médico-Cirujano</div>
                  <div className="OrderConfirmedPreviewModalPDFDoctorName" >RUT: 15.946.308-7&emsp;RCM: 27978-1</div>
                </div>
                <div className="OrderConfirmedPreviewModalPDFContactDiv">
                  <div className="OrderConfirmedPreviewModalPDFContactText">En caso de dudas o consultas, contáctanos</div>
                  <div className="OrderConfirmedPreviewModalPDFContactFlex">
                    <div className="OrderConfirmedPreviewModalPDFContactLeft">
                      <img className="OrderConfirmedPreviewModalPDFContactIcon" alt='' src={PhoneIcon} />
                      <span className="OrderConfirmedPreviewModalPDFContactLowerText"> &nbsp; +56 9 7127 3898 </span>
                    </div>
                    <div className="OrderConfirmedPreviewModalPDFContactRight">
                      <img className="OrderConfirmedPreviewModalPDFContactIcon" alt='' src={EmailIcon} />
                      <span className="OrderConfirmedPreviewModalPDFContactLowerText"> &nbsp; contacto@yomecontrolo.cl </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null
              }
            </Modal.Body>
            <Modal.Footer>
              <div className="OrderConfirmedPreviewModalFooter">
                <div className="OrderConfirmedPreviewModalFooterCloseButton" onClick={() => setOpenModal(false)}>CERRAR</div>
              </div>
            </Modal.Footer>
        </Modal>
      </div>
}
    </>
  );
};

export default OrderConfirmedCreator;