import React, { useState } from 'react';

const primaryButtonStyle = {
  zIndex: 1,
  // visibility: "hidden",
  color: 'white',
  backgroundColor: '#6633ff',
  border: '#6633ff solid 3px',
  transition: 'all 0.3s',
};
const SecondaryButtonStyle = {
  zIndex: 1,
  // visibility: "hidden",
  color: '#6633ff',
  backgroundColor: 'white',
  border: '#6633ff solid 3px',
  transition: 'all 0.3s',
};

// Button Primary
export const PrimaryButton = ({ onClick, label, ClassName, bg = true, PadLeft="0px", PadRight="0px", PadTop="1vh", PadBottom="1vh", Height="fit-content" }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div style={{
        ...primaryButtonStyle,
        cursor: "pointer",
        fontSize: 'inherit',
        height: Height,
        padding:  `${PadTop} ${PadRight} ${PadBottom} ${PadLeft}`,
        border: isActive ? 'none' : primaryButtonStyle.border,
        boxShadow: isHovered ? 'rgba(52, 0, 102, 0.24) 0px 3px 8px' : isActive ? 'rgba(52, 0, 102, 0.16) 0px 1px 4px' : null

      }}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onMouseOut={() => setIsActive(false)}

        className={ClassName}>
        {label}
      </div>

    </>
  );
};

// Button Secondary
export const SecondaryButton = ({ onClick, label, ClassName, bg = true, PadLeft="0px", PadRight="0px", PadTop="1vh", PadBottom="1vh", Height="fit-content"  }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  return (
    <>
      <div style={{
        ...SecondaryButtonStyle,
        fontSize: 'inherit',
        padding:  `${PadTop} ${PadRight} ${PadBottom} ${PadLeft}`,
        height: Height,
        cursor: "pointer",
        border: isActive ? 'none' : SecondaryButtonStyle.border,
        boxShadow: isHovered ? 'rgba(52, 0, 102, 0.24) 0px 3px 8px' : isActive ? 'rgba(52, 0, 102, 0.16) 0px 1px 4px' : null
      }}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={() => setIsActive(true)}
        onMouseUp={() => setIsActive(false)}
        onMouseOut={() => setIsActive(false)}

        className={ClassName}>
        {label}
      </div>
    </>
  );

};


// Button 3
export const Button3 = ({ onClick, label }) => (
  <button onClick={onClick}>{label}</button>
);
