import { useState, createContext, useEffect } from "react";

export const UserInfo = createContext();

const UserInfoProvider = (props) => {

  //Data Route Vars
  const [Active, setActive] = useState(() => {
    const localData = localStorage.getItem("Active");
    return localData ? JSON.parse(localData) :
    {"male": "GenderTile", "female": "GenderTile", "NB": "GenderTile"}
  });

 const [Name, setName] = useState(() => {
    const localData = localStorage.getItem("Name");
    return localData ? JSON.parse(localData) : "";
  });

  const [DoB, setDoB] = useState(() => {
    const localData = localStorage.getItem("DoB");
    return localData ? JSON.parse(localData) : new Date();
  });

  const [DoBMonth, setDoBMonth] = useState(() => {
    const localData = localStorage.getItem("DoBMonth");
    return localData ? JSON.parse(localData) : DoB.getMonth()+1;
  });
    
  const [DoBDay, setDoBDay] = useState(() => {
    const localData = localStorage.getItem("DoBDay");
    return localData ? JSON.parse(localData) : DoB.getDate();
  });
    
  const [DoBYear, setDoBYear] = useState(() => {
    const localData = localStorage.getItem("DoBYear");
    return localData ? JSON.parse(localData) :DoB.getFullYear();
  });

  const [Age, setAge] = useState(() => {
    const localData = localStorage.getItem("Age");
    return localData ? JSON.parse(localData) : 0;
  });

  const [Rut, setRut] = useState(() => {
    const localData = localStorage.getItem("Rut");
    return localData ? JSON.parse(localData) : "";
  });

  const [Email, setEmail] = useState(() => {
    const localData = localStorage.getItem("Email");
    return localData ? JSON.parse(localData) : "";
  });

  const [Gender, setGender] = useState(() => {
    const localData = localStorage.getItem("Gender");
    return localData ? JSON.parse(localData) : "";
  });

  const [Phone, setPhone] = useState(() => {
    const localData = localStorage.getItem("Phone");
    return localData ? JSON.parse(localData) : "";
  });

  const [Weight, setWeight] = useState(() => {
    const localData = localStorage.getItem("Weight");
    return localData ? JSON.parse(localData) : "";
  });
 
  const [Height, setHeight] = useState(() => {
    const localData = localStorage.getItem("Height");
    return localData ? JSON.parse(localData) : "";
  });

  const [Recent, setRecent] = useState(() => {
    const localData = localStorage.getItem("Recent");
    return localData ? JSON.parse(localData) : "<1Año";
  });

  const [LoggedIn, setLoggedIn] = useState(() => {
    const localData = localStorage.getItem("LoggedIn");
    return localData ? JSON.parse(localData) : false;
  });

  const [LoggedOut, setLoggedOut] = useState(() => {
    const localData = localStorage.getItem("LoggedOut");
    return localData ? JSON.parse(localData) : false;
  });

  const [SessionExpired, setSessionExpired] = useState(() => {
    const localData = localStorage.getItem("SessionExpired");
    return localData ? JSON.parse(localData) : false;
  });

  const [HeartAgeCalculated, setHeartAgeCalculated] = useState(() => {
    const localData = localStorage.getItem("HeartAgeCalculated");
    return localData ? JSON.parse(localData) : false;
  });
 
  useEffect(() => {
    localStorage.setItem("Active", JSON.stringify(Active));
  }, [Active]);
 
  useEffect(() => {
    localStorage.setItem("Name", JSON.stringify(Name));
  }, [Name]);
 
  useEffect(() => {
    localStorage.setItem("DoBMonth", JSON.stringify(DoBMonth));
  }, [DoBMonth]);
 
  useEffect(() => {
    localStorage.setItem("DoBDay", JSON.stringify(DoBDay));
  }, [DoBDay]);
 
  useEffect(() => {
    localStorage.setItem("DoBYear", JSON.stringify(DoBYear));
  }, [DoBYear]);
 
  useEffect(() => {
    localStorage.setItem("DoB", JSON.stringify(DoB));
  }, [DoB]);

  useEffect(() => {
    localStorage.setItem("Age", JSON.stringify(Age));
  }, [Age]);

  useEffect(() => {
    localStorage.setItem("Rut", JSON.stringify(Rut));
  }, [Rut]);


  useEffect(() => {
    localStorage.setItem("Email", JSON.stringify(Email));
  }, [Email]);

  useEffect(() => {
    localStorage.setItem("Gender", JSON.stringify(Gender));
  }, [Gender]);

  useEffect(() => {
    localStorage.setItem("Phone", JSON.stringify(Phone));
  }, [Phone]);
  
  useEffect(() => {
    localStorage.setItem("Weight", JSON.stringify(Weight));
  }, [Weight]);
    
  useEffect(() => {
    localStorage.setItem("Height", JSON.stringify(Height));
  }, [Height]);

  useEffect(() => {
    localStorage.setItem("Recent", JSON.stringify(Recent));
  }, [Recent]);

  useEffect(() => {
    localStorage.setItem("LoggedIn", JSON.stringify(LoggedIn));
  }, [LoggedIn]);

  useEffect(() => {
    localStorage.setItem("LoggedOut", JSON.stringify(LoggedOut));
  }, [LoggedOut]);

  useEffect(() => {
    localStorage.setItem("SessionExpired", JSON.stringify(SessionExpired));
  }, [SessionExpired]);

  useEffect(() => {
    localStorage.setItem("HeartAgeCalculated", JSON.stringify(HeartAgeCalculated));
  }, [HeartAgeCalculated]);
    


  //Medical Route Vars

  const [MedHis, setMedHis] = useState(() => {
    const localData = localStorage.getItem("MedHis");
    return localData ? JSON.parse(localData) : []
  });

  useEffect(() => {
    localStorage.setItem("MedHis", JSON.stringify(MedHis));
  }, [MedHis]);

  const [FamilyMedHis, setFamilyMedHis] = useState(() => {
    const localData = localStorage.getItem("FamilyMedHis");
    return localData ? JSON.parse(localData) : []
  });

  useEffect(() => {
    localStorage.setItem("FamilyMedHis", JSON.stringify(FamilyMedHis));
  }, [FamilyMedHis]);

    return (
        <UserInfo.Provider
          value={{Weight, setWeight, Height, setHeight,
 Name, setName, DoB, DoBYear, setDoBYear,  DoBDay, setDoBDay,  DoBMonth, setDoBMonth, 
 Rut, setRut, 
Email,setEmail,
 setDoB, Gender, setGender, Active, setActive, Age, setAge, Phone, setPhone,

  //Medical Route Vars
  MedHis, setMedHis, 
  //Family Route Vars
  FamilyMedHis, setFamilyMedHis,

  LoggedIn, setLoggedIn, LoggedOut, setLoggedOut, SessionExpired, setSessionExpired,
  Recent, setRecent, HeartAgeCalculated, setHeartAgeCalculated
          }}>
          {props.children}
        </UserInfo.Provider>
      );
    };
    export default UserInfoProvider;