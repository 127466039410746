import React from 'react';
import "../../Styles/DataRouteTitle.css";


function DataRouteTitle() {
    return (
     <h3 className="DataRouteTitleDiv">
        <div className="DataRouteTitleText"> 
        Empecemos con unos datos básicos para conocerte un poco 
        </div>
        <div className='DataRouteTitleProgress'>1/4</div>
     </h3>
    );
}

export default DataRouteTitle;