import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/MedicalHistoryForm.css";
import { PrimaryButton, SecondaryButton } from '../ThemeComponents/Buttons';
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../Context/UserInfoContext';
import medhistory from "../../../JSONS/medhistory.json"

function MedicalHistoryForm() {
  const Navigate = useNavigate();
  const { MedHis, setMedHis, DoB, Gender, Weight, Height
  } = useContext(UserInfo);

  const [ChosenMedhis, setChosenMedhis] = useState([]);
  const [TaskStart, setTaskStart] = useState(false);
  const [TaskDone, setTaskDone] = useState(false);
  const [OneTime, setOneTime] = useState(true);
  const age = new Date().getFullYear() - new Date(DoB).getFullYear();
  const ageCat = age <= 40 ? "15-40" : age <= 60 ? "41-60" : ">60"
  const gender = Gender == 1 ? "M" : "F"
  const IMC = Weight/(Height/100)**2

  useEffect(() => {
    if(age < 15) {
      Navigate("/recomendador/paso-1")
    }
    if(TaskDone) {
      Navigate("/recomendador/paso-3")
    }
  })

  useEffect(() => {
    if(OneTime && document.getElementById("ManageAll")) {
      PreSelect()
      setOneTime(false)
    }
  })

  function PreSelect() {
    let tmpSobr = document.getElementById("Sobrepeso")
    let tmpObes = document.getElementById("Obesidad")
    let tmpList = ChosenMedhis
    let which = 0
    switch(parseInt(age)) {
      case 15:
        if(IMC >= 22.8 && IMC < 27.5) {
          which = 1
        } else if(IMC >= 27.5) {
          which = 2
        }
        break;
      case 16:
        if(IMC >= 23.6 && IMC < 28.4) {
          which = 1
        } else if(IMC >= 28.4) {
          which = 2
        }
        break;
      case 17:
        if(IMC >= 24.4 && IMC < 29.1) {
          which = 1
        } else if(IMC >= 29.1) {
          which = 2
        }
        break;
      default:
        if(IMC >= 25 && IMC < 30) {
          which = 1
        } else if(IMC >= 30) {
          which = 2
        }
        break;
    }
    if(which === 1) {
      tmpSobr.className = tmpSobr.className.replace("GenderTile", "GenderTileActive");
      tmpList.push("Sobrepeso")
    } else if(which === 2) {
      tmpObes.className = tmpObes.className.replace("GenderTile", "GenderTileActive");
      tmpList.push("Obesidad")
    }
  }

  const handleGender = (ActiveKey) => {
    let tmp = document.getElementById(ActiveKey);
    let tmpList = ChosenMedhis

    if(tmp.className.includes("GenderTileActive")) {
      tmp.className = tmp.className.replace("GenderTileActive", "GenderTile");
      let index = tmpList.indexOf(ActiveKey);
      if (index > -1) { 
        tmpList.splice(index, 1); 
      }
    } else {
      tmp.className = tmp.className.replace("GenderTile", "GenderTileActive");
      tmpList.push(ActiveKey)
    }
    setChosenMedhis([...tmpList])
  }

  const getPacks = async() => {
    setTaskStart(true)
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        "Content-Type": "application/json",
      },
      //body: JSON.stringify({})
    };

    try{
      
    const resp = await fetch("https://ymc-graph-api.azurewebsites.net/getpacks", requestOptions)
     if (resp.status !== 200){
      alert("there has been some error");
      return false;
     }
     const data = await resp.json();
     //console.log(data)
     
     //await SetRelatedExams(data)
     localStorage.setItem('AllPacks', JSON.stringify(data));

     //console.log(RelatedExams)
     setTaskDone(true)
    }
    catch(error){
      console.log("there has been an error login in")
      alert("Ocurrió un error procesando los resultados")
      return false
    }
  };


  return (
    <div className="MedicalHistoryFormDiv" id="ManageAll">

      <div className="MedicalHistoryFormTitle">
        ¿Te han diagnosticado o estás en tratamiento por alguna de estas patologías o condiciones crónicas?
      </div>
      <div className='MedicalHistoryFormButtons'>
        {Object.keys(medhistory.Personal).filter(a => medhistory.Personal[a].age_cat.includes(ageCat)).filter(a => medhistory.Personal[a].gender.includes(gender)).map((history) => (
          <div className={"MedicalFormIndividualButton GenderTile rounded-2 "} id={history} key={history} onClick={() => handleGender(history)}>
            {history} </div>
        ))}

      </div>
     <div className='ButtonsMedHis'>
        <SecondaryButton ClassName={"MedicalHistoryRouteBackButton rounded-2 text-center"} label={"Volver atrás"} onClick={() => Navigate(-1)} />
        {TaskStart ?
        <PrimaryButton ClassName={"MedicalHistoryRouteNextButton rounded-2 text-center"} label={"Cargando..."} />
        : <PrimaryButton ClassName={"MedicalHistoryRouteNextButton rounded-2 text-center"} label={"Siguiente"} onClick={() => {setMedHis([...ChosenMedhis]); setTaskDone(true)}} />}
     </div>

    </div>
  );
}

export default MedicalHistoryForm;