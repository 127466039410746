function HeartAgeCalc(gender, age, smoke, sistolic, choles) {

    age = parseInt(age)
    sistolic = parseInt(sistolic)
    choles = parseInt(choles)*0.02586
    let smoking = smoke === "NO" ? 0 : 1
    
    let so = 0
    let so10 = 0
    let so_nc = 0
    let so_nc10 = 0
    let w = (0.24*(choles-6))+(0.018*(sistolic-120))+(0.71*smoking)
    let w_nc = (0.02*(choles-6))+(0.022*(sistolic-120))+(0.63*smoking)

    if(gender === "F") {
        so = Math.exp(-(Math.exp(-29.8))*((age-20)**6.36))
        so10 = Math.exp(-(Math.exp(-29.8))*((age-10)**6.36))
        so_nc = Math.exp(-(Math.exp(-31))*((age-20)**6.62))
        so_nc10 = Math.exp(-(Math.exp(-31))*((age-10)**6.62))
    } else {
        so = Math.exp(-(Math.exp(-22.1))*((age-20)**4.71))
        so10 = Math.exp(-(Math.exp(-22.1))*((age-10)**4.71))
        so_nc = Math.exp(-(Math.exp(-26.7))*((age-20)**5.64))
        so_nc10 = Math.exp(-(Math.exp(-26.7))*((age-10)**5.64))
    }

    let s_chd = so**Math.exp(w)
    let s_chd10 = so10**Math.exp(w)
    let s_nochd = so_nc**Math.exp(w_nc)
    let s_nochd10 = so_nc10**Math.exp(w_nc)

    let s10_chd = s_chd === 0 ? 0 : s_chd10/s_chd
    let s10_nochd = s_nochd10/s_nochd

    let risk_10chd = 1-s10_chd
    let risk_10nochd = 1-s10_nochd

    let cvd_risk10 = risk_10chd+risk_10nochd

    let heart_age = 0
    let risk = 0
    let risk_cat = 0

    let points = cvd_risk10*100

    let results = {}

    if(gender ==='F') {
        if (points < 0.05)
            heart_age = 40
        else if (points < 0.06)
            heart_age = 41
        else if (points < 0.07)
            heart_age = 42
        else if (points < 0.08)
            heart_age = 43
        else if (points < 0.1)
            heart_age = 44
        else if (points < 0.12)
            heart_age = 45
        else if (points < 0.14)
            heart_age = 46
        else if (points < 0.17)
            heart_age = 47
        else if (points < 0.2)
            heart_age = 48
        else if (points < 0.23)
            heart_age = 49
        else if (points < 0.27)
            heart_age = 50
        else if (points < 0.31)
            heart_age = 51
        else if (points < 0.36)
            heart_age = 52
        else if (points < 0.41)
            heart_age = 53
        else if (points < 0.47)
            heart_age = 54
        else if (points < 0.54)
            heart_age = 55
        else if (points < 0.61) 
            heart_age = 56
        else if (points < 0.69) 
            heart_age = 57
        else if (points < 0.78) 
            heart_age = 58
        else if (points < 0.88) 
            heart_age = 59
        else if (points < 0.99) 
            heart_age = 60
        else if (points < 1.12) 
            heart_age = 61
        else if (points < 1.25) 
            heart_age = 62
        else if (points < 1.4) 
            heart_age = 63
        else if (points < 1.56) 
            heart_age = 64
        else if (points < 1.74) 
            heart_age = 65
        else if (points < 1.93) 
            heart_age = 66
        else if (points < 2.14) 
            heart_age = 67
        else if (points < 2.36) 
            heart_age = 68
        else if (points < 2.61) 
            heart_age = 69
        else if (points < 2.88) 
            heart_age = 70
        else if (points < 3.16) 
            heart_age = 71
        else if (points < 3.47) 
            heart_age = 72
        else if (points < 3.81) 
            heart_age = 73
        else if (points < 4.17) 
            heart_age = 74
        else if (points < 4.56) 
            heart_age = 75
        else if (points < 4.98) 
            heart_age = 76
        else if (points < 5.42) 
            heart_age = 77
        else if (points < 5.9)
            heart_age = 78
        else if (points < 6.41)
            heart_age = 79
        else if (points < 6.96)
            heart_age = 80
        else if (points < 7.54)
            heart_age = 81
        else if (points < 8.17)
            heart_age = 82
        else if (points < 8.83)
            heart_age = 83
        else if (points < 9.53)
            heart_age = 84
        else if (points < 10.28)
            heart_age = 85
        else if (points < 11.07)
            heart_age = 86
        else if (points < 11.91)
            heart_age = 87
        else if (points < 12.8)
            heart_age = 88
        else if (points < 13.74)
            heart_age = 89
        else
            heart_age = 90
    } else {
        if (points < 0.23)
            heart_age = 40
        else if (points < 0.27)
            heart_age = 41
        else if (points < 0.3)
            heart_age = 42
        else if (points < 0.35)
            heart_age = 43
        else if (points < 0.4)
            heart_age = 44
        else if (points < 0.45)
            heart_age = 45
        else if (points < 0.51)
            heart_age = 46
        else if (points < 0.57)
            heart_age = 47
        else if (points < 0.64)
            heart_age = 48
        else if (points < 0.72)
            heart_age = 49
        else if (points < 0.81)
            heart_age = 50
        else if (points < 0.9)
            heart_age = 51
        else if (points < 1)
            heart_age = 52
        else if (points < 1.1)
            heart_age = 53
        else if (points < 1.22)
            heart_age = 54
        else if (points < 1.34)
            heart_age = 55
        else if (points < 1.48)
            heart_age = 56
        else if (points < 1.62)
            heart_age = 57
        else if (points < 1.77)
            heart_age = 58
        else if (points < 1.94)
            heart_age = 59
        else if (points < 2.12)
            heart_age = 60
        else if (points < 2.31)
            heart_age = 61
        else if (points < 2.51)
            heart_age = 62
        else if (points < 2.72)
            heart_age = 63
        else if (points < 2.95)
            heart_age = 64
        else if (points < 3.19)
            heart_age = 65
        else if (points < 3.45)
            heart_age = 66
        else if (points < 3.72)
            heart_age = 67
        else if (points < 4.01)
            heart_age = 68
        else if (points < 4.31)
            heart_age = 69
        else if (points < 4.63)
            heart_age = 70
        else if (points < 4.97)
            heart_age = 71
        else if (points < 5.33)
            heart_age = 72
        else if (points < 5.71)
            heart_age = 73
        else if (points < 6.11)
            heart_age = 74
        else if (points < 6.52)
            heart_age = 75
        else if (points < 6.96)
            heart_age = 76
        else if (points < 7.42)
            heart_age = 77
        else if (points < 7.9)
            heart_age = 78
        else if (points < 8.41)
            heart_age = 79
        else if (points < 8.94)
            heart_age = 80
        else if (points < 9.49)
            heart_age = 81
        else if (points < 10.07)
            heart_age = 82
        else if (points < 10.67)
            heart_age = 83
        else if (points < 11.3)
            heart_age = 84
        else if (points < 11.96)
            heart_age = 85
        else if (points < 12.65)
            heart_age = 86
        else if (points < 13.36)
            heart_age = 87
        else if (points < 14.1)
            heart_age = 88
        else if (points < 14.87)
            heart_age = 89
        else
            heart_age = 90
    }

    if (points <= 1) {
        risk = 0
        risk_cat = "Bajo" // Entre 0 y 1
    } else if (points <=2) {
        risk = 1
        risk_cat = "Moderado" // Entre 1 y 2
    } else if (points <=3) {
        risk = 2
        risk_cat = "Moderado" // Entre 2 y 3
    } else if (points <=5) {
        risk = 3
        risk_cat = "Moderado" // Entre 3 y 5
    } else if (points <10) {
        risk = 4
        risk_cat = "Alto" // Entre 5 y 10
    } else {
        risk = 5
        risk_cat = "Muy Alto" // 10 o más
    }

    results["heartAge"] = heart_age
    results["cardioRisk"] = risk
    results["cardioRiskCat"] = risk_cat

    return results
};

export default HeartAgeCalc;