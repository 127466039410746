import React from 'react';
import "./Styles/DataRoute.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
import DataRouteTitle from './Components/DataRoute/DataRouteTitle';
import DataRouteForm from './Components/DataRoute/DataRouteForm';
import UserInfoProvider from './Components/Context/UserInfoContext';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function DataRoute() {
    return (
       <UserInfoProvider> 
            <div className="Page">
                <div className="DataRouteNavBar">      
                        <NavBar/>
                     </div>
                <div className="DataRouteTitle">
                    <DataRouteTitle/>    
                </div>
                <div className="DataRouteForm">
                    <DataRouteForm/>
                </div>
                <div className="DataRouteFooter">
                    <Footer/>
                </div>
        </div>

         </UserInfoProvider>
    );
}

export default DataRoute;