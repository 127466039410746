import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/ExamChoiceByRecom.css";
import { useNavigate } from 'react-router-dom';
import PackRectangle from "../../Photos/PackRectangle.png"
import { Packs } from '../Context/PacksContext';
import { MedicalContext } from '../Context/MedicalContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import TinyPageIcon from "../../Photos/TinyPageIcon.png"
import TinyExamIcon from "../../Photos/TinyExamIcon.png"
import packslist from "../../../JSONS/updatedPacks.json"
import examslist from "../../../JSONS/updatedExams.json"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import MakeItEasy from "../Landing/MakeItEasy"


function ExamChoiceByRecomRoute() {

  const Navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [VisualizePack,SetVisualizePack] = useState(searchParams.get("pack"))

  const MySwal = withReactContent(Swal)

  const {
    PreviewRelatedExams, SetPreviewRelatedExams, SetRelatedExams
  } = useContext(MedicalContext);

  const {
    AllPacks
  } = useContext(Packs);

  useEffect(() => {
    if(localStorage.getItem("PreviewRelatedExams") === null | localStorage.getItem("PreviewRelatedExams").length === 2) {
      Navigate("/recomendador/paso-1")
    }
  })

  window.onscroll = function() {
    if(document.URL.includes("recomendador/paso-4")) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }

  const others = ["Imagenología", "Procedimientos"]

  const [ChosenExams, setChosenExams] = useState([]);
  const [OneTime, setOneTime] = useState(true);
  const [Checkout, setCheckout] = useState(false);
  const [Page,setPage] = useState(1)
  const [ExamsShown,setExamsShown] = useState(window.innerWidth <= 420 ? 1 : window.innerWidth <= 720 ? 2 : 4)
  const [PackPage,setPackPage] = useState(1)
  const [PacksShown,setPacksShown] = useState(window.innerWidth <= 620 ? 1 : window.innerWidth <= 920 ? 2 : 3)
  const [ChosenToSee, setChosenToSee] = useState(false);

  useEffect(() => {
      if(ChosenToSee) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          Navigate("/detalles-de-pack?pack=".concat(VisualizePack))
      }
  });

  function ChangePack(pack) {
      SetVisualizePack(pack); 
      setChosenToSee(true);
  };

  useEffect(() => {
      if(OneTime && document.getElementById("ManageAll")) {
      PreSelect()
      setOneTime(false)
      }
      if(Checkout) {
          Navigate("/orden-medica")
      }
  })

  function PreSelect() {
      let Selected = PreviewRelatedExams
      let loadedExams = PreviewRelatedExams.filter(a => examslist[a].emission !== "")
      for(let i=0;i<loadedExams.length;i++) {
          if(Selected.includes(loadedExams[i])) {
          let tmp = document.getElementById(loadedExams[i])
          tmp.checked = true
          }
      }
      setChosenExams(Selected.filter(a => loadedExams.includes(a)))
      //console.log(loadedExams)
      //let checked={Selected.includes(key) ? true : false}
  }

  function handleChosen(id) {
    let tmp = document.getElementById(id)
    let listtmp = ChosenExams
    if(id == 'ChooseAll') {
        if (tmp.checked) {
          for(let i=0; i<PreviewRelatedExams.length; i++) {
            let tmp2 = document.getElementById(PreviewRelatedExams[i])
            if(tmp2) {
              tmp2.checked = true
              listtmp.push(PreviewRelatedExams[i])
            }
          }
        } else {
            listtmp = []
            for(let i=0; i<PreviewRelatedExams.length; i++) {
                let tmp2 = document.getElementById(PreviewRelatedExams[i])
                if(tmp2) tmp2.checked = false
            }
        }
    }
    else {
      if (tmp.checked) {
        listtmp.push(id)
      } else {
        let index = listtmp.indexOf(id);
        if (index > -1) { 
          listtmp.splice(index, 1); 
        }
      }
    }
    //console.log([...new Set(listtmp)])
    setChosenExams([...new Set(listtmp)])
  }

  function ModifyPackCheckout() {
    SetRelatedExams(ChosenExams)
    setCheckout(true)
  }

  function toggleAdditional(id) {
    let tmp = document.getElementById(id)
    if(tmp.className.includes("InformationCollapsed")) {
      tmp.className = tmp.className.replace(" InformationCollapsed", "");
    } else {
      tmp.className += " InformationCollapsed"
    }
  }

  function toggleDescription(id,type) {
    let tmp = document.getElementById(id.concat("More"))
    let tmp2 = document.getElementById(id.concat("Less"))
    if(type === "More") {
        tmp.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
        tmp2.className = tmp2.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
    } else {
        tmp.className = tmp.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
        tmp2.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
    }
  }

  function changeExamPage(num) {
    setPage(num+Page)
    let tmp = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
    for(let i=0;i<tmp.length;i++) {
      if(tmp[i].className.includes("ActiveDot")) {
        tmp[i].className = tmp[i].className.replace(" ActiveDot", "");
      }
    }
    let tmp2 = document.getElementById('pageDot'.concat(num+Page))
    tmp2.className += " ActiveDot"
  }

  function ChangeNumberOfExams() {
    let current = ExamsShown
    let current2 = PacksShown
    let different = false
    let different2 = false

    if(window.innerWidth <= 420) {
      setExamsShown(1)
      if(current != 1) different = true
    } else if(window.innerWidth <= 720) {
      setExamsShown(2)
      if(current != 2) different = true
    } else {
      setExamsShown(4)
      if(current != 4) different = true
    }

    if(window.innerWidth <= 620) {
      setPacksShown(1)
      if(current2 != 1) different2 = true
    } else if(window.innerWidth <= 920) {
      setPacksShown(2)
      if(current2 != 2) different2 = true
    } else {
      setPacksShown(3)
      if(current2 != 3) different2 = true
    }

    if(different) {
      setPage(1)
      let tmp = document.getElementsByClassName("ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot")
      for(let i=0;i<tmp.length;i++) {
        if(tmp[i].className.includes("ActiveDot")) {
          tmp[i].className = tmp[i].className.replace(" ActiveDot", "");
        }
      }
      let tmp2 = document.getElementById('pageDot'.concat(1))
      if (tmp2) tmp2.className += " ActiveDot"
    }

    if(different2) {
      setPackPage(1)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", ChangeNumberOfExams);
    return () => window.removeEventListener("resize", ChangeNumberOfExams);
  }, [ChangeNumberOfExams]);
  
    return (
      <>
      {PreviewRelatedExams.length === 0 ?
      null 
    :
    <>
    <div className="ExamChoiceByGenderAllOuter">
      <div className="ExamChoiceByRecomAllTitleOuterDiv">
        <h3 className="ExamChoiceByRecomAllTitleDiv">
          <div className="DataRouteTitleText"> 
          Recomendación de exámenes
          </div>
          <div className='DataRouteTitleProgress'>4/4</div>
        </h3>
      </div>
     <div className="ExamChoiceByRecomAll" id="ManageAll">
      <div className="ExamChoiceByRecomLeftSide">
        <div className="ExamChoiceByRecomPackBoxImage" style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 100%),url(" + PackRectangle + ")",backgroundPosition: 'center',backgroundPositionY: 'top',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
        <div className="ExamChoiceByRecomPackTextContainter">
          <h3 className="ExamChoiceByRecomPackTitle">Recomendación Personalizada</h3>
          <div className="ExamChoiceByRecomPackDescription">Ponte al día en tu control con este chequeo específico para tu sexo, edad e historial médico. Es un buen comienzo para ponerte al día con lo que has postergado, mantener la salud y evitar la enfermedad. ¡Para vivir más, y mejor!</div>
          <div className='ExamChoiceByRecomPackPriceFlex'>
            <div className='ExamChoiceByRecomPackPrice' id="PriceDiv">$2990</div>
            {ChosenExams.length === 0 ? 
            <div className='ExamChoiceByRecomPackAddToOrderButtonDisabled'>Generar Orden Médica</div>
            :
            <div className='ExamChoiceByRecomPackAddToOrderButton' 
                onClick={() =>  ModifyPackCheckout()}>Generar Orden Médica</div>
}
          </div>
        </div>
      </div>
      <div className="ExamChoiceByRecomRightSide">
        <div className="ExamChoiceByRecomChoiceContainer">
          <div className="ExamChoiceByRecomChoiceRightText">
            Exámenes Preventivos a tu Medida
          </div>
          <div className="ExamChoiceByRecomChoiceDescription">
            Un chequeo preventivo te permitirá conocer tu estado de salud para evitar aparición de enfermedades y abordaje temprano de posibles síntomas.
            Escoge aquí los exámenes que te interesan de nuestra recomendación.
          </div>
          {/* 
          <div className="ExamChoiceByRecomChoiceChooseAllDiv">
            <input type="checkbox" name="selectExam" className="ExamChoiceByRecomChoiceChooseAllInput" id={'ChooseAll'} onClick={() => handleChosen('ChooseAll')}/>
            <span className="ExamChoiceByRecomChoiceChooseAllText">Elegir todos</span>
          </div> */}
          <>
          {PreviewRelatedExams.map(a => examslist[a].emission).includes('Laboratorio') ?
          <>
            <div className="ExamChoiceByRecomChoiceLabel">
              Laboratorio
            </div>
            <div className="ExamChoiceByRecomChoiceExams">
            <>
            {PreviewRelatedExams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Laboratorio" ?
              <>
              <div className="ExamChoiceByRecomChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByRecomChoiceExamSelect" id={key} onClick={() => handleChosen(key)}/>
                <span className="ExamChoiceByRecomChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByRecomChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByRecomChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </>
            </div> 
          </>
          : null}
          {others.some((el) => PreviewRelatedExams.map(a => examslist[a].emission).includes(el)) ?
          <>
            <div className="ExamChoiceByRecomChoiceLabel">
              Imagenología y Procedimientos
            </div>
            <div className="ExamChoiceByRecomChoiceExams">
            {PreviewRelatedExams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Imagenología" | examslist[key].emission === "Procedimientos" ?
              <>
              <div className="ExamChoiceByRecomChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByRecomChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByRecomChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByRecomChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByRecomChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          {PreviewRelatedExams.map(a => examslist[a].emission).includes('Derivaciones') ?
          <>
            <div className="ExamChoiceByRecomChoiceLabel">
              Derivaciones
            </div>
            <div className="ExamChoiceByRecomChoiceExams">
            {PreviewRelatedExams.map((key) => (
              <div key={key}>
              {examslist[key].emission === "Derivaciones" ?
              <>
              <div className="ExamChoiceByRecomChoiceExamsFlexed">
                <input type="checkbox" name="selectExam" className="ExamChoiceByRecomChoiceExamSelect" id={key} onClick={() => handleChosen(key)} />
                <span className="ExamChoiceByRecomChoiceExamName">{examslist[key].name}</span>
                {examslist[key].additional === "" ?
                null :
                  <div className="ExamChoiceByRecomChoiceExamInfo" onClick={() => toggleAdditional("Added".concat(key))}>?</div>
                }
              </div>
              <div className="ExamChoiceByRecomChoiceSpecifics InformationCollapsed" id={"Added".concat(key)}>{examslist[key].additional}</div>
              </>
              :
              null}
              </div>
            ))}
            </div>
          </>
          : null}
          </> 
        </div>
      </div>
     </div>

     <div className='ExamChoiceByGenderAllExamsDivContainer'>
      <div className='ExamChoiceByGenderAllExamsDiv'>
        <h5 className="ExamChoiceByGenderAllExamsPageMainTitle">Exámenes:&ensp; ({PreviewRelatedExams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "").length}) &nbsp;
          <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
        </h5>
        <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
          <div className="ExamChoiceByGenderAllExamsFlexFullLine">
            {Page === 1 ?
            null :
            <div className='ExamChoiceByGenderAllExamsLeftArrowDiv' onClick={() => changeExamPage(-1)}>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className='ExamChoiceByGenderAllExamsLeftArrow text-end fa-rotate-180'
              />
            </div>
            }
            <div className="ExamChoiceByGenderAllExamsFlexExamLine">
              {PreviewRelatedExams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "").slice(ExamsShown*(Page-1),ExamsShown*Page).map((exam) => (
              <div className="ExamChoiceByGenderAllExamsSingleExamBlock" key={exam}>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                  <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                </div>
                <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
              </div>
              ))}
            </div>
            {Page === Math.ceil(PreviewRelatedExams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "").length / ExamsShown) ?
            null :
            <div className='ExamChoiceByGenderAllExamsRightArrowDiv' onClick={() => changeExamPage(1)}>
              <FontAwesomeIcon 
                icon={faArrowRightLong} 
                className='ExamChoiceByGenderAllExamsRightArrow text-end'
              />
            </div>
            }
          </div>
          <div className='ExamChoiceByGenderAllExamsDotsDiv'>
            <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot ActiveDot" id="pageDot1"></div>
            {[...Array(Math.ceil(PreviewRelatedExams.filter(obj => examslist[obj].emission !== "Derivaciones" && examslist[obj].emission !== "").length / ExamsShown)-1).keys()].map((dots) => (
              <div className="ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot" key={'pageDot'.concat(dots+2)} id={'pageDot'.concat(dots+2)}></div>
            ))}
          </div>

          {PreviewRelatedExams.filter(obj => examslist[obj].emission === "Derivaciones").length !== 0 ?
         <>
        <div className="ExamChoiceByGenderAllExamsDerivations">
          <h5 className="ExamChoiceByGenderAllExamsPageMainTitle">Derivaciones:&ensp; ({PreviewRelatedExams.filter(obj => examslist[obj].emission === "Derivaciones").length}) &nbsp;
            <img className="ExamChoiceByGenderAllExamsPageIcon" alt='' src={TinyPageIcon} />
          </h5>
          <div className="ExamChoiceByGenderAllExamsHorizontalLine"></div>
            <div className="ExamChoiceByGenderAllExamsFlexFullLine">
              <div className="ExamChoiceByGenderAllDerivationsFlexExamLine">
                {PreviewRelatedExams.filter(obj => examslist[obj].emission === "Derivaciones").map((exam) => (
                <div className="ExamChoiceByGenderAllDerivationsSingleExamBlock" key={exam}>
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockUpLine">
                    <img className="ExamChoiceByGenderAllExamsExamIcon" alt='' src={TinyExamIcon} />&ensp;
                    <div className="ExamChoiceByGenderAllExamsSingleExamBlockName">{examslist[exam].name}</div>
                  </div>
                  <div className="ExamChoiceByGenderAllExamsSingleExamBlockDescr">{examslist[exam].description}</div>
                </div>
                ))}
              </div>
            </div>
          </div>
          </> : null}
        </div>
      </div>

        {AllPacks.length !== 0 ?
        <div className="ExamChoiceByRecomPacksDiv">
            <div className="ExamChoiceByRecomPacksTitle">Complementa tu chequeo con alguna de estas sugerencias</div>
            <div className="ExamChoiceByRecomPacksPackBoxesFullLineFlex">
              {PackPage === 1 ?
              null :
              <div className='ExamChoiceByRecomPacksLeftArrowDiv' onClick={() => setPackPage(PackPage-1)}>
                <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className='ExamChoiceByRecomPacksLeftArrow text-end fa-rotate-180'
                />
              </div>
              }

              <div className="ExamChoiceByRecomPacksPackBoxesFlex">
                  {AllPacks.slice(PacksShown*(PackPage-1),PacksShown*PackPage).map((pack) => (
                  <div className="ExamChoiceByRecomPacksPackBox" key={pack}>
                    <div className='ExamsByPacksPackBoxBackImage' style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 50%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                      <div className="ExamChoiceByRecomPacksPackBoxWhiteBox">
                          <div className="ExamChoiceByRecomPacksPackBoxWhiteBoxTitle">{packslist[pack].name}</div>
                          <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packslist[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                              <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                          </div>
                          <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packslist[pack].description} &nbsp;
                              <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                          </div>
                          <div className="ExamChoiceByRecomPacksPackBoxWhiteBoxFlex">
                              <div className="ExamChoiceByRecomPacksPackBoxWhiteBoxPrice">${packslist[pack].price}</div>
                          </div>
                          <div className="ExamChoiceByRecomPackBoxWhiteBoxMoreInfoButton" onClick={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0; ChangePack(pack);}}>Más Información</div>
                      </div>
                  </div>
                  ))}
              </div>

              {PackPage === Math.ceil(AllPacks.length / PacksShown) ?
              null :
              <div className='ExamChoiceByRecomPacksRightArrowDiv' onClick={() => setPackPage(PackPage+1)}>
                <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className='ExamChoiceByRecomPacksRightArrow text-end'
                />
              </div>
              }

            </div>
        </div>
        : null}
      </div>
        <MakeItEasy/>

        <div className='ScrollBackToTop rounded-pill ScrollBackToTopHidden' id="ToTheTop" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
          <FontAwesomeIcon 
              icon={faArrowUp} 
              className='ScrollBackToTopUpArrow'
          />
        </div>
        </>
}
        </>
    );
}

export default ExamChoiceByRecomRoute;