import React from "react";
import MedicalContextProvider from "./Components/Context/MedicalContext";
import LogContextProvider from "./Components/Context/LoginContext";
import OrderConfirmedCreator from "./Components/OrderConfirmedRoute/OrderConfirmedCreator";
import BasketProvider from "./Components/Context/BasketContext";
import UserInfoProvider from "./Components/Context/UserInfoContext";
import PacksProvider from './Components/Context/PacksContext';
import NavBar from '../NavBar';
import Footer from '../Footer';
// import RoutesBar from "./Components/RoutesBar";
import "./Styles/OrderConfirmedCreator.css";


const OrderConfirmedRoute = () => {
  return (
    <>
        <BasketProvider>
         <UserInfoProvider>
           <LogContextProvider>
            <MedicalContextProvider>
            <PacksProvider>
            <div className="OrderPage">
                <div className="OrderPageNavBar">      
                        <NavBar/>
                     </div>
              <div className="">
                <OrderConfirmedCreator/>
              </div>
              <div className="OrderPageFooter">
                <Footer/>
              </div>
        </div>
        </PacksProvider>
             </MedicalContextProvider>
           </LogContextProvider>
          </UserInfoProvider>
        </BasketProvider>
    </>
  );
};

export default OrderConfirmedRoute;
