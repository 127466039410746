import React from "react";
import "./NewDesign/Styles/Footer.css";
import YMCTransparent from "./NewDesign/Photos/YMCTransparent.svg";
import YMCBigLogoTransparent from "./NewDesign/Photos/YMCBigLogoTransparent.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

function Footer() {
  const Navigate = useNavigate();

  return (
    <div className="PageFooter">
      <div className="PageFooterContent">
        <div className="PageFooterUpperLogoDiv">
          <img className="PageFooterUpperLogo" src={YMCTransparent}/>
        </div>
        <div className="PageFooterFlexedLinks">
          <div className="PageFooterLeftLogoDiv">
            <img className="PageFooterLeftLogo" src={YMCBigLogoTransparent}/>
          </div>
          <div className="PageFooterLinkColumnsFlex">
            <div className="PageFooterLinkColumn">
              <div className="PageFooterLinkColumnTitle">Inicio</div>
              <div className="PageFooterLinkColumnSingleLink" onClick={() => {window.scrollTo(0, 0); Navigate("/packs-de-examenes");}}>Exámenes</div>
              {/*<div className="PageFooterLinkColumnSingleLink">Categorías</div>*/}
              <div className="PageFooterLinkColumnSingleLink" onClick={() => {window.scrollTo(0, 0); Navigate("/quienes-somos");}}>Nosotros</div>
              {/*<div className="PageFooterLinkColumnSingleLink">Prevención</div>
              <div className="PageFooterLinkColumnSingleLink">Medicina Preventiva</div>*/}
            </div>
            <div className="PageFooterLinkColumn">
              <div className="PageFooterLinkColumnTitle">Recursos</div>
              <div className="PageFooterLinkColumnSingleLink" onClick={() => {Navigate("/contacto?scroll=FAQ");}}>Preguntas Frecuentes</div>
              <div className="PageFooterLinkColumnSingleLink" onClick={() => {window.scrollTo(0, 0); Navigate("/contacto");}}>Contacto</div>
            </div>
            <div className="PageFooterLinkColumn">
              <div className="PageFooterLinkColumnTitle">Legal</div>
              <div className="PageFooterLinkColumnSingleLink"><a className="PageFooterLinkColumnSingleLink_a" href="https://static.yomecontrolo.cl/public/pdf/GENERAL-TERMS-YMC.pdf" target="_blank">Términos y Condiciones</a></div>
              {/*<div className="PageFooterLinkColumnSingleLink">Políticas de Privacidad</div>*/}
            </div>
            <div className="PageFooterLinkColumn"></div>
            {/*
            <div className="PageFooterLinkColumn">
              <div className="PageFooterLinkColumnTitle">Empresas</div>
              <div className="PageFooterLinkColumnSingleLink">Sitio Seguro</div>
              <div className="PageFooterLinkColumnSingleLink">Pago Seguro</div>
            </div>*/}
          </div>
        </div>
      </div>
      <div className="PageFooterBottomText">YoMeControlo 2024</div>
    </div >
  );
}
export default Footer;
