import React, { useContext, useEffect, useState } from 'react';
import "../../Styles/ExamRecommendationBasket.css";
import { BasketContext } from '../Context/BasketContext';
import { MedicalContext } from '../Context/MedicalContext';
import { Packs } from '../Context/PacksContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserInfo } from '../Context/UserInfoContext';
import { Navigate, useNavigate } from 'react-router-dom';
import TinyProfileIcon from "../../Photos/TinyProfileIcon.png"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function ExamRecommendationBasket() {
  const Nav = useNavigate();

  const MySwal = withReactContent(Swal)

  const {
    Basket, setBasket, ExamsForOrder, setExamsForOrder, setOrderExamsCodes, OrderExamsCodes, setDiscounted
  } = useContext(BasketContext);

  const {
    Name, Rut, DoB, Gender, Email, setEmail, setName, setGender, setRut, Age, setAge, Phone
  } = useContext(UserInfo);

  const {
    RelatedExams
  } = useContext(MedicalContext);

  const {
    BasketPacks, ExamsPerProduct, SetPurchased, SetAdded,
    ExamediSelected, ExamediRegion, ExamediCommune, ExamediAllowed, SetExamediWrong
  } = useContext(Packs);

  useEffect(() => {
    if(onetime) {
      setExamsForOrder([...RelatedExams])
      setOrderExamsCodes([...RelatedExams])
      setDiscounted(0)
      SetExamediWrong(false)
      setOnetime(false)
    }

    if(fullyReady) {
      Nav("/mi-orden-medica")
    }
  })

  //const [visibleExams, setVisibleExams] = useState({})
  const [Active, setActive] = useState({"male": "GenderTile", "female": "GenderTile"})
  const [TOSMissing, setTOSMissing] = useState(false)
  const [ExamediIncomplete, setExamediIncomplete] = useState(false)
  const [Paying, setPaying] = useState(false)
  const [ValidCoupon, setValidCoupon] = useState('')
  const [PriceTotal, setPriceTotal] = useState(RelatedExams.length === 0 ? 0 : 2990)
  const [Discount, setDiscount] = useState(0)
  //const [SubTotal, setSubTotal] = useState(0)
  //const [Discount, setDiscount] = useState(1000)
  const [Loading, setLoading] = useState(false)
  const [Coupon, setCoupon] = useState("")
  const [onetime, setOnetime] = useState(true)
  const [onetime2, setOnetime2] = useState(true)
  const [onetime3, setOnetime3] = useState(true)
  const [onetime4, setOnetime4] = useState(false)
  const [fullyReady, setFullyReady] = useState(false)
  const [tmpRut, setTmpRut] = useState(Rut)
  const [tmpName, setTmpName] = useState(Name)
  const [tmpEmail, setTmpEmail] = useState(Email)
  const [tmpGender, setTmpGender] = useState(Gender)
  const [ProfileTab, setProfileTab] = useState(true)
  const [numberOfBlocks, setNumberOfBlocks] = useState(0);

  useEffect(() => {
    if(Name === "" | Rut === "" | Gender === "" | Email === "") {
      setProfileTab(false)
    }
    if(RelatedExams.length === 0 & onetime3) {
      setOnetime4(true)
      setOnetime3(false)
    }
  })

  useEffect(() => {
    if(numberOfBlocks !== BasketPacks.length | onetime4) {
      let totalExams = []
      let payment = 0
      for(let i = 0; i<BasketPacks.length; i++) {
        payment += ExamsPerProduct[BasketPacks[i]].price
        totalExams = totalExams.concat(ExamsPerProduct[BasketPacks[i]].exams)
      }
    
      if(RelatedExams.length !== 0) {
        totalExams = totalExams.concat(RelatedExams)
        payment += 2990
      }
      setPriceTotal(payment)
      setOrderExamsCodes([...new Set(totalExams)])

      if(ValidCoupon & onetime2) {
        setPriceTotal(payment * Discount)
        setOnetime2(false)
      }

      setNumberOfBlocks(BasketPacks.length)

      if(onetime4) setOnetime4(false)
    }
  })

  const executeScroll = () => document.getElementById('go-to-examedi').scrollIntoView();

  const handleName = (value) => {
    const result = value.replace(/\d/g, '');
    setTmpName(result);
  };

  const [validation, setvalidation] = useState({
    "Name": "valid",
    "Rut": "valid",
    "Gender": "valid",
    "Email": "valid"
  });

  function HandleSubmit() {

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    const isRutValid = checkRut(tmpRut) ? "valid" : "invalid"
    const isNameValid = Object.keys(tmpName).length === 0 ? "invalid" : tmpName.trim() === "" ? "invalid" : "valid";
    const isEmailValid = tmpEmail.match(validRegex) === false ? "invalid" : tmpEmail.trim() === "" ? "invalid" : "valid";
    const isGenderValid = Object.values(Active).every(value => value === "GenderTile") ? "invalid" : "valid";

    setvalidation({
      "Rut": isRutValid,
      "Name": isNameValid,
      "Gender": isGenderValid,
      "Email": isEmailValid
    });

    if (
      isNameValid === "valid" &&
      isRutValid === "valid" &&
      isGenderValid === "valid" &&
      isEmailValid === "valid" &&
      !ProfileTab
    ) {
      setName(tmpName)
      setRut(tmpRut)
      setGender(tmpGender)
      setEmail(tmpEmail)
      setProfileTab(true)
      SetAdded(false)
      if(Age === 0) {
        setAge(15)
      }
      MySwal.fire({
        position: "top-end",
        icon: "success",
        iconColor: "#6633FF",
        title: "Se han guardado los datos del paciente",
        showConfirmButton: false,
        timer: 1500
      })
      return true;
    } else return false;
  }

  const handleGender = (ActiveKey) => {
    setvalidation(prevState => ({
      ...prevState, // Spread the previous state
      ["Gender"]: Object.values(Active).every(value => value === "GenderTile") ? "valid" : "invalid"
    }));
    for (const key in Active) {
      setActive((State) => ({
        ...State,
        [key]: "GenderTile",
      }))
    }
    setTmpGender("")

    if (Active[ActiveKey] === "GenderTile") {
      setActive((State) => ({
        ...State,
        [ActiveKey]: "GenderTileActive",
      }))
      setTmpGender(ActiveKey === "male" ? 1 : 2)
      setvalidation(prevState => ({
        ...prevState, // Spread the previous state
        ["Gender"]: "valid"
      }));

    }
  }

  const UserAge = new Date().getFullYear() - new Date(DoB).getFullYear()

  function ValidateEmail(input) {

    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let validRegexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;

    let accepted = document.getElementById('TOS')

    const isRutValid = checkRut(tmpRut) ? "valid" : "invalid"
    const isNameValid = Object.keys(tmpName).length === 0 ? "invalid" : tmpName.trim() === "" ? "invalid" : "valid";
    const isEmailValid = tmpEmail.match(validRegex) === false ? "invalid" : tmpEmail.trim() === "" ? "invalid" : "valid";
    const isGenderValid = Object.values(Active).every(value => value === "GenderTile") ? "invalid" : "valid";

    let isPhoneValid = true
    if(!Phone.match(validRegexPhone) || Phone.length < 9 || Phone.length > 11) {
        isPhoneValid = false
    }

    if(isRutValid === "valid" & isNameValid === "valid" & isGenderValid === "valid" & (Name === "" | Rut === "")) {
      document.getElementById("saveWarning").innerHTML = "Por favor guarde los datos ingresados";
      setvalidation({
        "Rut": isRutValid,
        "Name": isNameValid,
        "Gender": isGenderValid,
        "Email": isEmailValid
      });
    } else {
      setvalidation({
        "Rut": isRutValid,
        "Name": isNameValid,
        "Gender": isGenderValid,
        "Email": isEmailValid
      });
    }

    if(ExamediSelected) {
      if(ExamediAllowed && ExamediCommune !== "" && ExamediRegion !== "" && isPhoneValid) {
        setExamediIncomplete(false)
      } else {
        setExamediIncomplete(true)
        SetExamediWrong(true)
        if(window.innerWidth <= 920) {
          executeScroll()
        }
        setTOSMissing(false)
        return
      }
    } else {
      setExamediIncomplete(false)
    }

    if(input) {
      if (Email.match(validRegex) && ProfileTab && Name !== "" && Rut !== "" && Gender !== "") {
        if(OrderExamsCodes.length > 52) {
          return MySwal.fire({
            icon: "error",
            title: "Límite de exámenes excedido",
            text: "No se permite tener más de 52 exámenes por orden médica. Por favor vuelva atrás y reduzca su selección.",
            showConfirmButton: true
          })
        }
        if(accepted.checked) {
          pay()
          return 
        } else {
          setTOSMissing(true)
          return
        }
      }

    } else {
      if(Email.match(validRegex) && ProfileTab && Name !== "" && Rut !== "" && Gender !== "") {
        if(OrderExamsCodes.length > 52) {
          return MySwal.fire({
            icon: "error",
            title: "Límite de exámenes excedido",
            text: "No se permite tener más de 52 exámenes por orden médica. Por favor vuelva atrás y reduzca su selección.",
            showConfirmButton: true
          })
        }
        if(accepted.checked) {
          SetPurchased(true)
          setFullyReady(true)
          return 
        } else {
          setTOSMissing(true)
          return
        }
      }
    }

  }

  const pay = () => {
    setPaying(true);
    SetPurchased(true)
    var params = {
      commerceOrder: Math.floor(Math.random() * (9999999 - 200000 + 1)) + 200000,
      subject: "Pago de orden medica",
      currency: "CLP",
      amount: PriceTotal,
      email: Email,
      urlConfirmation: "https://ymc-flow-api.azurewebsites.net/apiFlow/payment_confirm",
      urlReturn: "https://ymc-flow-api.azurewebsites.net/OrderConfirmedRoute"
    };

    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params)
    };
    fetch("https://ymc-flow-api.azurewebsites.net/apiFlow/create_order", requestOptions)
      .then(response => response.text())
      .then(result =>
        window.location.replace(result.slice(13, -2)))
      .catch(error => console.log('error', error));
  };

  async function checkCoupon() {
    setLoading(true)
    MySwal.fire({
      position: "center",
      iconColor: "#6633FF",
      imageUrl: "https://static.yomecontrolo.cl/public/icons/ymc-loading.gif",
      title: "Validando Cupón...",
      color: "#340066",
      imageWidth: 400,
      imageHeight: 400,
      showConfirmButton: false,
    })
    let str = Coupon.replace(/\s+/g, '');
    let person = 0;
    await fetch("https://ymc-doc-api.azurewebsites.net/validatecoupon", {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        coupon: str
      })
    })
      .then((res) => res.json()).then((responseData) => {
        if (responseData)
          return person = responseData
        else
          return person = false
      })
      .catch(error => console.log(error))
    if (person.discount) {
      //console.log(person)
      let pricereduce = PriceTotal * (100 - person.discount) / 100
      setDiscount((100 - person.discount) / 100)
      setDiscounted(person.discount)
      setPriceTotal(pricereduce)
      document.getElementById("valcoup").innerHTML = "Cupón validado";
      setValidCoupon(true)
      setLoading(false)
      MySwal.close()
      if(person.discount === 100)
        SetPurchased(true)
    }
    else {
      document.getElementById("valcoup").innerHTML = "Cupón inválido";
      setLoading(false)
      MySwal.close()
    }
  } 

  function checkRut(rut) {
    // Despejar Puntos
    var valor = rut.trim().replace(".", "");
    // Despejar Guión
    valor = valor.replace("-", "");

    // Aislar Cuerpo y Dígito Verificador
    var cuerpo = valor.slice(0, -1);
    var dv = valor.slice(-1).toUpperCase();

    // Formatear RUN
    setTmpRut(cuerpo.replace("-", "") + "-" + dv);

    // Si no cumple con el mínimo ej. (n.nnn.nnn)
    if (rut === "-") {
      setTmpRut("");
      return false;
    }

    if (cuerpo.length < 7) {

      return false;
    }

    // Calcular Dígito Verificador
    var suma = 0;
    var multiplo = 2;

    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i++) {
      // Obtener su Producto con el Múltiplo Correspondiente
      var index = multiplo * valor.charAt(cuerpo.length - i);

      // Sumar al Contador General
      suma = suma + index;

      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    // Calcular Dígito Verificador en base al Módulo 11
    var dvEsperado = 11 - (suma % 11);

    // Casos Especiales (0 y K)
    dv = dv === "K" ? 10 : dv;
    dv = parseInt(dv) === 0 ? 11 : dv;

    // Validar que el Cuerpo coincide con su Dígito Verificador
    if (parseInt(dvEsperado) !== parseInt(dv)) {
      return false;
    }

    // Si todo sale bien, eliminar errores (decretar que es válido)
    return true;
  }

  return (
    
      <div className='ExamRecommendationBasket'>
        <div id="examedi-title" style={{position: "relative"}}>
            <div id="go-to-examedi" style={{position: "absolute", top: "-700px", left: 0}}></div>
        </div>
        <div className='ExamRecommendationBasketProfileInfoDiv'>
          <div className='ExamRecommendationBasketProfileInfoTitleRow'>
            <img className="ExamRecommendationBasketProfileInfoTitleRowProfileIcon" alt='' src={TinyProfileIcon} />
            <div className='ExamRecommendationBasketProfileInfoTitle'>Paciente</div>
          </div>
          {ProfileTab ?
          <>
            <div className='ExamRecommendationBasketProfileInfoTabsFlex'>
              <div className='ExamRecommendationBasketProfileInfoTabBlockLeft ExamRecommendationBasketProfileInfoTabActiveBlock'>Datos del Paciente</div>
              <div className='ExamRecommendationBasketProfileInfoTabBlockRight' onClick={() => setProfileTab(false)}>Confirmar Datos Paciente</div>
            </div>
            <div className='ExamRecommendationBasketProfileInfoPatientData'>
              <div className='ExamRecommendationBasketProfileInfoPatientDataFlex'>
                <div className='ExamRecommendationBasketProfileInfoPatientDataType'>Nombre:&nbsp;</div>
                <div className='ExamRecommendationBasketProfileInfoPatientName'>{Name}</div>
              </div>
              <div className='ExamRecommendationBasketProfileInfoPatientDataFlex'>
                <div className='ExamRecommendationBasketProfileInfoPatientDataType'>Rut:&nbsp;</div>
                <div className='ExamRecommendationBasketProfileInfoPatientRut'>{Rut}</div>
              </div>
              <div className='ExamRecommendationBasketProfileInfoPatientDataFlex'>
                <div className='ExamRecommendationBasketProfileInfoPatientDataType'>Edad:&nbsp;</div>
                <div className='ExamRecommendationBasketProfileInfoPatientAge'>{Age}</div>
              </div>
              <div className='ExamRecommendationBasketProfileInfoPatientDataFlex'>
                <div className='ExamRecommendationBasketProfileInfoPatientDataType'>Email:&nbsp;</div>
                <div className='ExamRecommendationBasketProfileInfoPatientEmail'>{Email}</div>
              </div>
              <div className='ExamRecommendationBasketProfileInfoPatientDataFlex'>
                <div className='ExamRecommendationBasketProfileInfoPatientDataType'>Sexo:&nbsp;</div>
                <div className='ExamRecommendationBasketProfileInfoPatientGender'>{Gender === 1 ? "Hombre" : "Mujer"}</div>
              </div>
            </div>
          </>
          :
          <>
            <div className='ExamRecommendationBasketProfileInfoTabsFlex'>
                <div className='ExamRecommendationBasketProfileInfoTabBlockLeft' onClick={() => setProfileTab(true)}>Datos del Paciente</div>
                <div className='ExamRecommendationBasketProfileInfoTabBlockRight ExamRecommendationBasketProfileInfoTabActiveBlock'>Confirmar Datos Paciente</div>
              </div>
            <div className='ExamRecommendationBasketProfileInfoChangePatientData'>
              <input
                value={tmpName}
                className="InputTile rounded-1"
                style={{ border: validation["Name"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                id="YMCName"
                maxLength={70}
                onChange={(e) => handleName(e.target.value)}
                placeholder="Ingresa tu nombre completo" />
                {validation["Name"] === "invalid" ?
                    <div className='NameAlert' style={{ color: "red", fontWeight: "500" }}> Por favor ingrese nombre válido</div>
                : null}
              <input
                value={tmpRut}
                className="InputTile rounded-1"
                style={{ border: validation["Rut"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                id="YMCRut"
                maxLength={15}
                onChange={(e) => checkRut(e.target.value)}
                placeholder="Ingresa tu rut" />
                {validation["Rut"] === "invalid" ?
                    <div className='RutAlert' style={{ color: "red", fontWeight: "500" }}> Por favor ingrese rut válido</div>
                : null}
              <div className="ExamRecommendationBasketProfileInfoChangePatientDataAge">
                <div className="ExamRecommendationBasketProfileInfoChangePatientDataAgeText">Ingresa tu edad: </div>
                <select defaultValue={Age} id="YMCAge" className='DoBTile rounded-2' onChange={(e) => setAge(e.target.value)}>
                  {Array.from({ length: 85 }, (_, i) => i+15).map((day) => (
                    <option key={day} value={parseInt(day)}>{day}</option>
                  ))}
                </select>
              </div>
              <input
                value={tmpEmail}
                className="InputTile rounded-1"
                style={{ border: ValidCoupon === false ? "red solid 1px" : "#cff9ff solid 1px", width: "100%", paddingInline: "1vh", marginInlineEnd: "0.5vw" }}
                id="YMCEmail"
                maxLength={60}
                onChange={(e) => setTmpEmail(e.target.value)}
                placeholder="Ingresar Email" />
                {validation["Email"] === "invalid" ?
                    <div className='EmailAlert' style={{ color: "red", fontWeight: "500" }}> Por favor ingrese email válido</div>
                : null}
              <div className="ExamRecommendationBasketProfileInfoChangePatientDataGender">
                <div className="ExamRecommendationBasketProfileInfoChangePatientDataGenderText">Ingresa tu género: </div>
                <div className={"GenderMale rounded-2 " + Active["male"]} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("male")}>
                  Hombre <br/>
                  <img className={"HeartAgeCalculatorRightSideSurveyQ1TileIcon" + (Active["male"] === "GenderTileActive" ? " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" : "")} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png" id="MaleTileIcon"/>
                </div>
                <div className={"GenderFemale rounded-2 " + Active["female"]} style={{ border: validation["Gender"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }} onClick={() => handleGender("female")}>
                  Mujer <br/>
                  <img className={"HeartAgeCalculatorRightSideSurveyQ1TileIcon" + (Active["female"] === "GenderTileActive" ? " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" : "")} src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleFemaleIconT.png" id="FemaleTileIcon"/>
                </div>
              </div>
              {validation["Gender"] === "invalid" ?
                <div className='GenderAlert' style={{ color: "red", visibility: validation["Gender"] === "invalid" ? "visible" : "collapse", fontWeight: "500" }}> Por favor seleccione su sexo al nacer</div>
              : null}
              <div className='ExamRecommendationBasketProfileInfoChangePatientDataButton' onClick={() => HandleSubmit()}>Guardar Datos</div>
              <div className='RutAlert' id={"saveWarning"} style={{ color: "red", fontWeight: "500" }}></div>
            </div>
          </>
}
        </div>
        {RelatedExams.length === 0 & BasketPacks.length === 0 ?
          null :
        <div className='ExamRecommendationBasketGetOrderDiv'>
          <div className='ExamRecommendationBasketGetOrderTitle'>Generar Orden Médica</div>
          {PriceTotal === 0 ?
          <>
            <div className='ExamRecommendationBasketCouponDiv'>
              <div className='ExamRecommendationBasketCouponText'>Ingresar código de descuento</div>
              <div className='ExamRecommendationBasketCouponFlex'>
                <input
                  value={Coupon}
                  className="ExamRecommendationBasketCouponInput rounded-1"
                  style={{ border: ValidCoupon === false ? "red solid 1px" : "#cff9ff solid 1px", width: "100%", paddingInline: "1vh", marginInlineEnd: "0.5vw" }}
                  id="YMCCoupon"
                  maxLength={20}
                  onChange={(e) => setCoupon(e.target.value)}
                  placeholder="Ingresa el nombre del cupón"
                  disabled />
                <div className='ExamRecommendationBasketCouponValidateButtonDisabled' onClick={() => null}>Validar</div>
              </div>
              <span id="valcoup" className='ValidOrNotCoupon' ></span>
            </div>
            <div className='ExamRecommendationBasketCouponDiv'>
              <div className='ExamRecommendationBasketTOSFlex'>
                <input type="checkbox" name="acceptTOS" className="ExamRecommendationBasketTOSCheck" id="TOS" />&ensp;
                <div className='ExamRecommendationBasketCouponText'>Acepto los <a className='ExamRecommendationBasketEmailInfoLink' href="https://static.yomecontrolo.cl/public/pdf/GENERAL-TERMS-YMC.pdf" target="_blank">términos y condiciones</a>.</div>
              </div>
              <div className='ExamRecommendationBasketEmailInfoText'>Enviaremos un correo con la boleta y un enlace para que puedas recuperar tu orden médica.</div>
              <span id="valemail" className='ValidOrNotEmail' style={{ color: "red", fontWeight: "500" }}></span>
            </div>
            {TOSMissing ?
              <div className='ExamRecommendationBasketTOSAlert'>Por favor aceptar términos y condiciones</div>
            : null}
            {ExamediIncomplete ?
              <div className='ExamRecommendationBasketTOSAlert'>Por favor completar los campos en el bloque de Examedi o des-seleccionar la opción.</div>
            : null}
            <div className='ExamRecommendationBasketGetOrderButton' onClick={() => ValidateEmail(false)}>Obtén tu Órden Médica</div>
          </>
          :
          <>
          <div className='ExamRecommendationBasketCouponDiv'>
            <div className='ExamRecommendationBasketCouponText'>Ingresar código de descuento</div>
            <div className='ExamRecommendationBasketCouponFlex'>
              <input
                value={Coupon}
                className="ExamRecommendationBasketCouponInput rounded-1"
                style={{ border: ValidCoupon === false ? "red solid 1px" : "#cff9ff solid 1px", width: "100%", paddingInline: "1vh", marginInlineEnd: "0.5vw" }}
                id="YMCCoupon"
                maxLength={20}
                onChange={(e) => setCoupon(e.target.value)}
                placeholder="Ingresa el nombre del cupón" />
              {Loading ?
              <div className='ExamRecommendationBasketCouponValidateButton'>Validando...</div>
              :
              <div className='ExamRecommendationBasketCouponValidateButton' onClick={() => checkCoupon()}>Validar</div>
                }
            </div>
            <span id="valcoup" className='ValidOrNotCoupon' ></span>
          </div>
          <div className='ExamRecommendationBasketCouponDiv'>
            <div className='ExamRecommendationBasketTOSFlex'>
              <input type="checkbox" name="acceptTOS" className="ExamRecommendationBasketTOSCheck" id="TOS" />&ensp;
              <div className='ExamRecommendationBasketCouponText'>Acepto los <a className='ExamRecommendationBasketEmailInfoLink' href="https://static.yomecontrolo.cl/public/pdf/GENERAL-TERMS-YMC.pdf" target="_blank">términos y condiciones</a>.</div>
            </div>
            <div className='ExamRecommendationBasketEmailInfoText'>Enviaremos un correo con la boleta y un enlace para que puedas recuperar tu orden médica.</div>
          </div>
          <div className='ExamRecommendationBasketTotalFlex'>
            <div className='ExamRecommendationBasketPriceText'>Total</div>
            <div className='ExamRecommendationBasketPriceText'>${PriceTotal}</div>
          </div>
          {TOSMissing ?
            <div className='ExamRecommendationBasketTOSAlert'>Por favor aceptar términos y condiciones.</div>
          : null}
          {ExamediIncomplete && ExamediSelected && (!ExamediAllowed || ExamediCommune === "" || ExamediRegion === "" || Phone.length < 9 || Phone.length > 11) ?
            <div className='ExamRecommendationBasketTOSAlert'>Por favor completar los campos en el bloque de Examedi o des-seleccionar la opción.</div>
          : null}
          { Paying ?
          <div className='ExamRecommendationBasketGetOrderButton'>Redirigiendo...</div>
          :
          <div className='ExamRecommendationBasketGetOrderButton' onClick={() => ValidateEmail(true)}>Pasar a Pagar</div>
}
          </>
}
        </div>
}
</div>
  );
}

export default ExamRecommendationBasket;