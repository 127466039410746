import React from 'react';
import "./Styles/ExamChoiceByGender.css";
import NavBar from '../NavBar';
import ExamChoiceByGender from './Components/ExamChoiceByGenderRoute/ExamChoiceByGender';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from './Components/Context/MedicalContext';
import PacksProvider from "./Components/Context/PacksContext";
import Footer from '../Footer';

function ExamChoiceByGenderRoute() {
    
    return (
        <UserInfoProvider>
            <MedicalContextProvider>
            <PacksProvider>
       <BrowserView>
            <div className="ExamChoiceByGenderRoutePage">
                <div className="ExamChoiceByGenderRouteNavBar">      
                    <NavBar/>
                </div>
                <div className="ExamChoiceByGenderRoute">
                    <ExamChoiceByGender/>
                </div>
                <div className="ExamChoiceByGenderContact">
                    <Footer/>
                </div>
            </div>
       </BrowserView>
        <MobileView>
            <div className="ExamChoiceByGenderRouteNavBar">      
                <NavBar/>
            </div>
            <div className="ExamChoiceByGenderRoute">
                <ExamChoiceByGender/>
            </div>
            <div className="ExamChoiceByGenderContact">
                <Footer/>
            </div>
</MobileView>
</PacksProvider>
</MedicalContextProvider>
       </UserInfoProvider>
    );
}

export default ExamChoiceByGenderRoute;