import React from "react";
import RemakeOrder from "./Components/RemakeOrder/RemakeOrder";
import NavBar from '../NavBar';
import Footer from '../Footer';
// import RoutesBar from "./Components/RoutesBar";
import "./Styles/RemakeOrder.css";

const RemakeOrderRoute = () => {
  return (
    <>
        <div className="OrderPage">
            <div className="OrderPageNavBar">      
                <NavBar/>
            </div>
            <div className="">
                <RemakeOrder/>
            </div>
            <div className="OrderPageFooter">
                <Footer/>
            </div>
        </div>
    </>
  );
};

export default RemakeOrderRoute;