import React from 'react';
import "./Styles/HeartAgeCalculator.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
import HeartAgeCalculator from './Components/HeartAgeCalculator/HeartAgeCalculator';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from './Components/Context/MedicalContext';

function HeartAgeCalculatorRoute() {
    return (
        <UserInfoProvider>
            <MedicalContextProvider>
                <div className="HeartAgeCalculatorRoutePage">
                    <div className="HeartAgeCalculatorRouteNavBar">      
                        <NavBar/>
                    </div>
                    <div className="HeartAgeCalculatorRouteForm">
                        <HeartAgeCalculator/>
                    </div>
                    <div className="HeartAgeCalculatorRouteFooter">
                        <Footer/>
                    </div>
                </div>
            </MedicalContextProvider>
       </UserInfoProvider>
    );
}

export default HeartAgeCalculatorRoute;