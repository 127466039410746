import React, { useContext, useState, useEffect } from 'react';
import "../../Styles/ExamsByPacks.css";
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../Context/UserInfoContext';
import { MedicalContext } from '../Context/MedicalContext';
import { Packs } from '../Context/PacksContext';
import PlusIcon from "../../Photos/PlusIcon.png"
import PackRectangle from "../../Photos/PackRectangle.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import packslist from "../../../JSONS/updatedPacks.json"
import MakeItEasy from "../Landing/MakeItEasy"
import LazyLoad from 'react-lazyload';


function ExamsByPacks() {
  const Navigate = useNavigate();

  const {
    SetChosenPackGender, ChosenPackGender
  } = useContext(Packs);

  const [VisualizePack,SetVisualizePack] = useState("")

  const [Gender, setGender] = useState(ChosenPackGender);
  const [Category, setCategory] = useState("Preventivo");
  const [ChosenToSee, setChosenToSee] = useState(false);
  const [PacksShown, setPacksShown] = useState(Object.keys(packslist).filter(a => packslist[a].category.includes("Preventivo") & packslist[a].gender === Gender))

  useEffect(() => {
    if(ChosenToSee) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        Navigate("/detalles-de-pack?pack=".concat(VisualizePack))
    }
  });

  window.onscroll = function() {
    if(document.URL.includes("packs-de-examenes")) {
      let tmp = document.getElementById("ToTheTop")
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        tmp.className = tmp.className.replace(" ScrollBackToTopHidden", "");
      } else {
        if(!tmp.className.includes("ScrollBackToTopHidden")) tmp.className += " ScrollBackToTopHidden"
      }
    }
  }

  function toggleGenderTab(current) {
    if(Gender !== current) {
        let tmp = document.getElementById("FemaleTab")
        let tmp2 = document.getElementById("MaleTab")
        let tmp3 = document.getElementById("MixedTab")
        switch(current) {
            case 'Mixed':
                setGender('Mixed')
                SetChosenPackGender('Mixed')
                tmp3.className += " ExamsByPacksActiveTag";
                tmp.className = tmp.className.replace(" ExamsByPacksActiveTag", "");
                tmp2.className = tmp.className.replace(" ExamsByPacksActiveTag", "");
                break;
            case 'M':
                setGender('M')
                SetChosenPackGender('M')
                tmp2.className += " ExamsByPacksActiveTag";
                tmp.className = tmp.className.replace(" ExamsByPacksActiveTag", "");
                tmp3.className = tmp.className.replace(" ExamsByPacksActiveTag", "");
                break;
            case 'F':
                setGender('F')
                SetChosenPackGender('F')
                tmp.className += " ExamsByPacksActiveTag";
                tmp2.className = tmp.className.replace(" ExamsByPacksActiveTag", "");
                tmp3.className = tmp.className.replace(" ExamsByPacksActiveTag", "");
                break;
        }
        if(Category === "AllCats") {
            setPacksShown(Object.keys(packslist).filter(a => packslist[a].gender === current))
        } else {
            setPacksShown(Object.keys(packslist).filter(a => packslist[a].category.includes(Category) & packslist[a].gender === current))
        }
    }
  }

  function toggleCategoryTab(current) {
    if(Category !== current) {
        let tmp = document.getElementsByClassName('ExamsByPacksCategoryTab')
        for(let i=0;i<tmp.length;i++) {
            if(tmp[i].classList.contains("ExamsByPacksActiveCategory")) {
                tmp[i].className = tmp[i].className.replace(" ExamsByPacksActiveCategory", "");
            }
        }
        let tmp2 = document.getElementById(current)
        tmp2.className += " ExamsByPacksActiveCategory"
        setCategory(current)

        if(current === "AllCats") {
            setPacksShown(Object.keys(packslist).filter(a => packslist[a].gender === Gender))
        } else {
            setPacksShown(Object.keys(packslist).filter(a => packslist[a].category.includes(current) & packslist[a].gender === Gender))
        }
    }
  }

  function toggleDescription(id,type) {
    let tmp = document.getElementById(id.concat("More"))
    let tmp2 = document.getElementById(id.concat("Less"))
    if(type === "More") {
        tmp.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
        tmp2.className = tmp2.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
    } else {
        tmp.className = tmp.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
        tmp2.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
    }
  }

  return (
    <div className="ExamsByPacksDiv">
        <div className="ExamsByPacksInnerDiv">
        <div className='ExamsByPacksUpTitle'>Compra tu orden médica</div>
        <div className='ExamsByPacksTitleFlex'>
            <img className="ExamsByPacksTitlePlusIcon" alt='' src={PlusIcon} />
            <h1 className='ExamsByPacksTitle'>Exámenes por pack</h1>
        </div>
        <div className='ExamsByPacksGenderTabsFlex'>
            <div className={ChosenPackGender === "Mixed" ? 'ExamsByPacksGenderTab ExamsByPacksActiveTag' : 'ExamsByPacksGenderTab'} id="MixedTab" onClick={() => toggleGenderTab('Mixed')}>Mixto</div>
            <div className={ChosenPackGender === "M" ? 'ExamsByPacksGenderTab ExamsByPacksActiveTag' : 'ExamsByPacksGenderTab'} id="MaleTab" onClick={() => toggleGenderTab('M')}>Hombres</div>
            <div className={ChosenPackGender === "F" ? 'ExamsByPacksGenderTab ExamsByPacksActiveTag' : 'ExamsByPacksGenderTab'} id="FemaleTab" onClick={() => toggleGenderTab('F')}>Mujeres</div>
        </div>
        <div className='ExamsByPacksCategoryTabsFlex'>
            <div className='ExamsByPacksCategoryTab ExamsByPacksActiveCategory' id="Preventivo" onClick={() => toggleCategoryTab("Preventivo")}>Preventivos</div>
            <div className='ExamsByPacksCategoryTab' id="Control" onClick={() => toggleCategoryTab("Control")}>Control</div>
            <div className='ExamsByPacksCategoryTab' id="Detección" onClick={() => toggleCategoryTab("Detección")}>Detección</div>
            <div className='ExamsByPacksCategoryTab' id="AllCats" onClick={() => toggleCategoryTab("AllCats")}>Todos</div>
        </div>
        <div className="ExamsByPacksPackBoxesFlex">
            {PacksShown.map((pack) => (
            <div className="ExamsByPacksPackBox" key={pack}>
                <LazyLoad once >
                <div className='ExamsByPacksPackBoxBackImage' style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 50%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                </LazyLoad>
                <div className="ExamsByPacksPackBoxGradient">
                    <div className="ExamsByPacksPackBoxWhiteBox">
                        <div className="ExamsByPacksPackBoxWhiteBoxTitle">{packslist[pack].name}</div>
                        <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packslist[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                            <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packslist[pack].description} &nbsp;
                            <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxFlex">
                            <div className="ExamsByPacksPackBoxWhiteBoxPrice">${packslist[pack].price}</div>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxMoreInfoButton" onClick={() => {SetVisualizePack(pack); setChosenToSee(true);}}>Más Información</div>
                    </div>
                </div>
            </div>
            ))}
            {Gender !== "Mixed" && Category === "AllCats" ?
            <>
            {Object.keys(packslist).filter(a => packslist[a].gender === "Mixed").map((pack) => (
            <div className="ExamsByPacksPackBox" key={pack}>
                <LazyLoad once >
                <div className='ExamsByPacksPackBoxBackImage' style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 50%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                </LazyLoad>
                <div className="ExamsByPacksPackBoxGradient">
                    <div className="ExamsByPacksPackBoxWhiteBox">
                        <div className="ExamsByPacksPackBoxWhiteBoxTitle">{packslist[pack].name}</div>
                        <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packslist[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                            <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packslist[pack].description} &nbsp;
                            <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxFlex">
                            <div className="ExamsByPacksPackBoxWhiteBoxPrice">${packslist[pack].price}</div>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxMoreInfoButton" onClick={() => {SetVisualizePack(pack); setChosenToSee(true);}}>Más Información</div>
                    </div>
                </div>
            </div>
            ))}
            </>
            : Gender !== "Mixed" ?
            <>
            {Object.keys(packslist).filter(a => packslist[a].category.includes(Category) & packslist[a].gender === "Mixed").map((pack) => (
            <div className="ExamsByPacksPackBox" key={pack}>
                <LazyLoad once >
                <div className='ExamsByPacksPackBoxBackImage' style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 50%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                </LazyLoad>
                <div className="ExamsByPacksPackBoxGradient">
                    <div className="ExamsByPacksPackBoxWhiteBox">
                        <div className="ExamsByPacksPackBoxWhiteBoxTitle">{packslist[pack].name}</div>
                        <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packslist[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                            <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packslist[pack].description} &nbsp;
                            <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxFlex">
                            <div className="ExamsByPacksPackBoxWhiteBoxPrice">${packslist[pack].price}</div>
                        </div>
                        <div className="ExamsByPacksPackBoxWhiteBoxMoreInfoButton" onClick={() => {SetVisualizePack(pack); setChosenToSee(true);}}>Más Información</div>
                    </div>
                </div>
            </div>
            ))}
            </>
            : null}
        </div>
        </div>
        <MakeItEasy/>
        <div className='ScrollBackToTop rounded-pill ScrollBackToTopHidden' id="ToTheTop" onClick={() => {document.body.scrollTop = 0; document.documentElement.scrollTop = 0;}}>
            <FontAwesomeIcon 
                icon={faArrowUp} 
                className='ScrollBackToTopUpArrow'
            />
        </div>
    </div>
    
  );
}

export default ExamsByPacks;
