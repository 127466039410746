import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {

    function displayAnswer(answer,arrow) {
        let tmp1 = document.getElementById(answer)
        let tmp2 = document.getElementById(arrow)
        if(tmp1.className.includes('ContactAnswerHidden')) {
            tmp1.className = tmp1.className.replace(" ContactAnswerHidden", "");
            tmp2.className += " FAQArrowDivRotation"
        } else {
            tmp1.className += " ContactAnswerHidden"
            tmp2.className = tmp2.className.replace(" FAQArrowDivRotation", "");
        }
    }

    return (
        <div className='ContactPageFAQ'>
            <div className='ContactPageFAQLeftSide'>
                <h3 className='ContactPageFAQUpTitle'>Preguntas Frecuentes</h3>
                <h1 className='ContactPageFAQTitle'>FAQs</h1>
                <div className='ContactPageFAQSubtitle'>Todo lo que necesitas saber sobre nuestros productos, servicios, facturación u otros los puedes encontrar aquí.</div>
            </div>
            <div className='ContactPageFAQRightSide'>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer1","Arrow1")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow1">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        1. ¿Qué es yomecontrolo.cl?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer1">Es una plataforma de telesalud que facilita la emisión de órdenes médicas de exámenes preventivos de manera remota y segura.</div>
                </div>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer2","Arrow2")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow2">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        2. ¿Dónde tiene validez la orden médica? ¿Dónde la puedo utilizar?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer2">La orden médica emitida tiene validez dentro de todo el territorio chileno, ya que es emitida por uno de nuestros médicos cirujanos acreditado por la Superintendencia de Salud de Chile. La orden de examen puede ser utilizada en cualquier laboratorio del país.</div>
                </div>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer3","Arrow3")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow3">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        3. ¿Cómo descargo la orden de examen?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer3">Primero debes seleccionar la orden que se ajusta a tus necesidades, añades los exámenes que deseas realizarte, completas tus datos personales (que serán incluidos en la orden de examen) y realizas el pago en línea mediante Transbank. Luego de realizado el pago, se despliega un botón de descarga con tu orden médica en formato pdf. Te enviaremos un mail con un link y una clave para que puedas acceder nuevamente a tu orden médica si es que lo necesitas.</div>
                </div>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer4","Arrow4")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow4">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        4. ¿Quién emite la orden médica?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer4">La orden médica de yomecontrolo.cl es emitida y firmada por un médico cirujano reconocido por la Superintendencia de Salud y registrado en el Colegio Médico de Chile.</div>
                </div>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer5","Arrow5")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow5">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        5. ¿Cuánto tiempo dura la orden de examen para ser utilizada?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer5">Como toda orden médica emitida en Chile, tiene una vigencia de 60 días dese su emisión, lo cual es garantizado por yomecontrolo.cl.</div>
                </div>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer6","Arrow6")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow6">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        6. ¿Por qué cuando selecciono algunos exámenes sube el precio final?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer6">El precio base de cada orden es 2.990 pesos, el cual incluye cierta cantidad de exámenes generales dependiendo de la categoría que hayas elegido. Algunos análisis un poco más específicos tienen un costo adicional y los hemos puesto en la misma lista a un precio menor para que puedan optar a ellos sin tener que pagar otra orden completa.</div>
                </div>
                <div className='ContactPageFAQQuestionBlock' onClick={() => displayAnswer("Answer7","Arrow7")}>
                    <h5 className='ContactPageFAQQuestionBlockQuestion'>
                        <div className='ContactPageFAQQuestionBlockArrowDiv' id="Arrow7">
                        <FontAwesomeIcon 
                            icon={faAngleRight} 
                            className='ContactPageFAQQuestionBlockArrow'
                        />
                        </div>
                        7. ¿Cuáles son las modalidades de pago?
                    </h5>
                    <div className='ContactPageFAQQuestionBlockAnswer ContactAnswerHidden' id="Answer7">Puedes realizar tu pago en línea mediante la plataforma de pago online FLOW y sus servicios asociados.</div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
