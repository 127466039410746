import React, { useState, useEffect, useContext } from 'react';
import "./NewDesign/Styles/NavBar.css";
import main_logo from "./NewDesign/Photos/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBookmark } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from 'react-router-dom';
import { Packs } from './NewDesign/Components/Context/PacksContext';
import { MedicalContext } from './NewDesign/Components/Context/MedicalContext';

function NavBar() {
  const Navigate = useNavigate();

  const [OrderChange, setOrderChange] = useState(true);

  const {
    BasketPacks, Purchased, SetPurchased, SetAdded
  } = useContext(Packs);

  const {
    RelatedExams
  } = useContext(MedicalContext);

  let plusOne = RelatedExams.length !== 0 ? 1 : 0

  const [CurrentBasket, setCurrentBasket] = useState(BasketPacks.length + plusOne);

  useEffect(() => {
    if(BasketPacks.length + plusOne != CurrentBasket && OrderChange && Purchased && !document.URL.includes("orden-medica") && !document.URL.includes("mi-orden-medica")) {
      SetPurchased(false)
      setOrderChange(false)
      SetAdded(false)
    }
  })

  function ToggleNavbarDisplay() {
    let x = document.getElementById("NavbarBurgerIcon")
    x.classList.toggle("change");
    if(BurgerMenu) {
      setBurgerMenu(false)
    } else {
      setBurgerMenu(true)
    }
  }

  function ToggleProfileDisplay() {
    let tmp = document.getElementById("NavbarProfileIconMenu")

    if(tmp.className.includes("animated")) {
      tmp.className = tmp.className.replace(" animated", "");
    } else {
      tmp.className += " animated"
    }
  }

  const [BurgerMenu, setBurgerMenu] = useState(false);

  return (
    <div className='BarNavFully'>
      <div className="BarNav">
        <div className="LogoNavBar text-center" onClick={() => Navigate("/")} >  <img src={main_logo} className="LogoUpTop" alt="logo" /> </div>

        {/* <div className="SearchExam "> 
        
          <Form.Control
              className="rounded-pill searchbarTop"
              id="SearchBar"
              type="text"
              placeholder="¿Qué exámen buscas?" />
          <div className="SearchIcon rounded-circle"><FontAwesomeIcon icon={faMagnifyingGlass}/></div>
  </div> */}
        <div className="NavbarRightSide">
          {/*<div className="ProfileIcon text-white text-center" onClick={() => ToggleProfileDisplay()}> <span className="NavbarBeginSession"></span> <FontAwesomeIcon icon={faUser} />*/}
          <div className="ProfileIcon text-white text-center" onClick={() => Navigate("/orden-medica")}> <span className="NavbarBeginSession">Orden Médica</span>
          {BasketPacks.length + plusOne === 0  ?
            null :
            <div className="ProfileNotification rounded-pill">{BasketPacks.length + plusOne}</div>
          }
          </div>
          <div className="NavbarBurgerIcon" id="NavbarBurgerIcon" onClick={() => ToggleNavbarDisplay()}>
            <div className="NavbarBurgerIconBar3"></div>
            <div className="NavbarBurgerIconBar1"></div>
            <div className="NavbarBurgerIconBar2"></div>
          </div>
        </div>
        {/* <div className="CartIcon text-white text-center"><FontAwesomeIcon icon={faCartShopping} />  </div> 
        <svg className="CartIcon text-white text-center" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30"><path d="M2.449 14.398l.447-.224-.447.224zm10.102 0l.447.224-.447-.224zM.703 6h13.594V5H.703v1zM14 5.703v.439h1v-.439h-1zM12.386 14H2.614v1h9.772v-1zM1 6.142v-.439H0v.439h1zm1.896 8.032A17.96 17.96 0 011 6.142H0c0 2.944.685 5.847 2.002 8.48l.894-.448zM2.614 14c.12 0 .229.068.282.174l-.894.448a.685.685 0 00.612.378v-1zm9.49.174a.315.315 0 01.282-.174v1c.26 0 .496-.146.612-.378l-.894-.448zM14 6.142c0 2.788-.65 5.538-1.896 8.032l.894.448A18.96 18.96 0 0015 6.142h-1zM14.297 6A.297.297 0 0114 5.703h1A.703.703 0 0014.297 5v1zM.703 5A.703.703 0 000 5.703h1A.297.297 0 01.703 6V5zm3.226.757l3-5L6.07.243l-3 5 .858.514zm4.142-5l3 5 .858-.514-3-5-.858.514z" fill="currentColor"></path></svg>
        <div className="SavedIcon text-white text-center"> <FontAwesomeIcon icon={faBookmark} /> </div> */}
      </div>
        <div className='NavbarProfileIconMenuContainer' id="NavbarProfileIconMenu">
            <div className='NavbarProfileIconMenu'>
                {/*<div className='NavbarProfileIconMenuItem'>Iniciar Sesión</div>*/}
                <div className='NavbarProfileIconMenuDivision'></div>
                <div className='NavbarProfileIconMenuItem NavbarProfileIconMenuItemMedicalOrder' onClick={() => Navigate("/orden-medica")}>Órden Médica
                {BasketPacks.length + plusOne === 0 ?
                  null :
                  <div className="MedicalOrderNotification rounded-pill">{BasketPacks.length + plusOne}</div>
                }
                </div>
                <div className='NavbarProfileIconMenuDivision'></div>
                <div className='NavbarProfileIconMenuItem'>Privacidad</div>
            </div>
        </div>
      { BurgerMenu ? 
      <div className='BarNavDisplayedOptions'>
        <div className='BarNavDisplayedOptionsButtons' onClick={() => Navigate("/")}>Inicio</div>
        <div className='BarNavDisplayedOptionsButtons' onClick={() => Navigate("/packs-de-examenes")}>Exámenes</div>
        <div className='BarNavDisplayedOptionsButtons' onClick={() => Navigate("/quienes-somos")}>Nosotros</div>
        <div className='BarNavDisplayedOptionsButtons' onClick={() => Navigate("/contacto?scroll=FAQ")}>Preguntas Frecuentes</div>
    {/*    <div className='BarNavDisplayedOptionsButtons'>Blog</div> */}
        <div className='BarNavDisplayedOptionsButtons' onClick={() => Navigate("/contacto")}>Contacto</div>
        <div className='BarNavDisplayedOptionsCalculatorButton'  onClick={() => Navigate("/edad-del-corazon")}>Mes del Corazón</div>
      </div>
:
null}
      <div className='BarNavLowerOptionsFully'>
        <div className='BarNavLowerOptions'>
          <div className='BarNavLowerOptionsButtons' onClick={() => Navigate("/")}>Inicio</div>
          <div className='BarNavLowerOptionsButtons' onClick={() => Navigate("/packs-de-examenes")}>Exámenes</div>
          <div className='BarNavLowerOptionsButtons' onClick={() => Navigate("/quienes-somos")}>Nosotros</div>
          <div className='BarNavLowerOptionsButtons' onClick={() => Navigate("/contacto?scroll=FAQ")}>Preguntas Frecuentes</div>
          {/*<div className='BarNavLowerOptionsButtons'>Blog</div>*/}
          <div className='BarNavLowerOptionsButtons' onClick={() => Navigate("/contacto")}>Contacto</div>
          <div className='BarNavLowerOptionsCalculatorButton' style={{width:'17%'}}  onClick={() => Navigate("/edad-del-corazon")}>Mes del Corazón</div>
        </div>
      </div>

    </div>
  );
}

export default NavBar;