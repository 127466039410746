import React from 'react';
import "../../Styles/DataRouteTitle.css";


function MedicalHistoryRouteTitle() {
    return (
        <h3 className="DataRouteTitleDiv">
            <div className="DataRouteTitleText"> 
            Respecto a tu estado de salud
            </div>
            <div className='DataRouteTitleProgress'>2/4</div>
        </h3>
    );
}

export default MedicalHistoryRouteTitle;