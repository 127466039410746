import React from 'react';
import "./Styles/WeAreYMC.css";
import NavBar from '../NavBar';
import WeAreYMC from './Components/WeAreYMCRoute/WeAreYMC';
//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import UserInfoProvider from './Components/Context/UserInfoContext';
import MedicalContextProvider from './Components/Context/MedicalContext';
import Footer from '../Footer';

function WeAreYMCRoute() {
    return (
        <UserInfoProvider>
            <MedicalContextProvider>
            <div className="WeAreYMCRoutePage">
                <div className="WeAreYMCRouteNavBar">      
                        <NavBar/>
                     </div>
                <div className="WeAreYMCRoute">
                    <WeAreYMC/>
                </div>
                <div className="WeAreYMCContact">
                    <Footer/>
                </div>
            </div>
</MedicalContextProvider>
       </UserInfoProvider>
    );
}

export default WeAreYMCRoute;