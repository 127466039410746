import React from 'react';
import "./Styles/Landing.css";
import NavBar from '../NavBar';
import LandingSlider from './Components/Landing/LandingSlider';
// import LandingPacks from './Components/Landing/LandingPacks';
import MedicalContextProvider from "./Components/Context/MedicalContext";
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';

function Landing() {
    const nav = useNavigate();
    return (

        <>
            <MedicalContextProvider>
                <div className="LandingPage">

                    <div className="NavBarLanding">
                        <NavBar/>
                    </div>
                    
                    <div className='LandingSlider'>
                        <LandingSlider/>
                    </div>
                    <div className="LandingContact">
                        <Footer/>
                    </div>
                </div>
            </MedicalContextProvider>
        </>

    );
}

export default Landing;