import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import MAN from './THEMAN.png';
import MAN_ENG from './THEMAN_ENG.png';
import MAN_PT from './THEMAN_PT.png';
import espa from './Chile.png';
import ingl from './UK.png';
import port from './BRZ.png';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: 'es',
        resources: {
            es: {
                translation: {
                    //// Login
                    login: 'INICIA SESIÓN',
                    pleaseLogin: 'Por favor ingrese su contraseña',
                    password: 'Contraseña',
                    //// Landing Page
                    //barSup
                    inicio: 'INICIO',
                    procesos: 'PROCESOS',
                    beneficios: 'BENEFICIOS',
                    costos: 'COSTOS',
                    esp: 'Español',
                    ing: 'Inglés',
                    port: 'Portugués',
                    flags: espa,
                    //HUGEMAN
                    no_filas: 'Una app que te permite tomar el control smart de tu salud',
                    HugemanSubtext: 'Nuestra plataforma de salud digital te acompaña en tu cuidado mediante análisis de datos (IA) y medicina predictiva, para que todos aquellos que quieran vivir activa y saludablemente en Chile y Latinoamérica puedan actuar para prevenir enfermedades o muertes prematuras al tomar acciones a tiempo.',
                    generate: 'Hacer Análisis Predictivo de Salud',
                    MANLY: MAN,
                    //PREVIEW
                    preview_helps_us: 'Ayúdanos a construir el futuro del cuidado de la salud personal y familiar',
                    preview_help_us_description: 'Gracias por tu interés en YMC, aún estamos en proceso de lanzamiento. Visítanos en los próximos días y verás de qué estamos hablando.',
                    //Como
                    how: '¿Cómo funciona Yo Me Controlo?',
                    head1: 'INICIA',
                    head2: 'SUGERENCIAS',
                    head3: 'ORDEN DE EXÁMENES',
                    card1: 'Ingresa algunos antecedentes familiares y personales',
                    card2: 'Selecciona los exámenes que deseas realizar',
                    card3: 'Genera tu orden de exámenes',
                    //AyD
                    ayd: 'Antes y Después',
                    SD: 'SIN YOMECONTROLO',
                    CD: 'CON YOMECONTROLO',
                    T1: 'Tienes que sacar hora al médico',
                    T2: 'Trasladarte al centro de salud',
                    T3: 'Pagar un bono de atención',
                    T4: 'Entrevistarte con tu médico para que éste te entregue una orden para tus exámenes',
                    T5: 'Ingresas a yomecontrolo.cl',
                    T6: 'Inicias el asistente virtual',
                    T7: 'Yomecontrolo te sugiere los exámenes que debes hacerte según tu perfil particular',
                    T8: 'Pagas y descargas tu orden del portal',
                    bigtext: '¡Más barato y más rápido!',
                    bigtext2: 'Además, te sugerimos laboratorios cerca de tu hogar para que puedas realizar tus exámenes',
                    //Cards
                    beforeYouGo: 'Antes de Continuar',
                    choosePacks: '¿Te gustaría añadir algunos packs a tu órden médica?',
                    access: 'Accede a un pack de exámenes rápidamente',
                    wanted: 'Exámenes más pedidos, no necesitas requisitos para obtener tu orden con ellos',
                    pack1: 'PACK CAPILAR',
                    pack2: 'SALUD LABORAL',
                    pack3: 'PACK CELÍACO',
                    pack4: 'EVALUACIÓN DE DROGAS',
                    pack5: 'ANTICUERPOS COVID',
                    pack6: 'ESTADO NUTRICIONAL',
                    pack7: 'VEGANO/VEGETARIANO',
                    pack8: 'DETECCIÓN DE ENFERMEDADES DE TRANSMISIÓN SEXUAL',
                    pack9: 'CHEQUEO CARDIOVASCULAR COMPLETO',
                    pack10: 'PERFIL RENAL Y EXÁMEN DE ORINA',
                    pack11: 'EVALUACIÓN PRE-DEPORTIVA',
                    pack12: 'EXÁMEN DE EMBARAZO (TEST SANGUÍNEO)',
                    ex1: 'EXÁMEN DE SANGRE',
                    ex2: 'ANTÍGENO PCR',
                    ex3: 'RECUENTOS DE LINFOCITOS ABSOLUTOS',
                    ex4: 'VELOCIDAD DE FILTRACIÓN GLOMERULA',
                    selecAll: 'SELECCIONAR TODOS',
                    r_less: 'Ver Menos',
                    r_more: 'Ver Más',
                    backButton: 'ATRÁS',
                    //Footer
                    MORE: 'LEER MÁS',
                    contacto: 'CONTACTO',
                    idioma: 'IDIOMA',
                    about: 'Sobre Nosotros',
                    policy: 'Nuestras Políticas',
                    tos: 'Términos y Condiciones',
                    dir: 'Av Condell 1015 Providencia Region Metropolitana',
                    finale: 'yomecontrolo.cl 2023 Todos los derechos reservados',
                    //// MedicalHistory
                    ant_familiares: 'INGRESA TUS ANTECEDENTES FAMILIARES',
                    fam_subtext: 'Al completar esta información podremos sugerirte algunos exámenes',
                    per_subtext: 'Al completar esta información podremos sugerirte algunos exámenes',
                    ant_personales: 'INGRESA TUS ANTECEDENTES PERSONALES',
                    diabetes: 'Diabetes',
                    cancer_prostata: 'Cáncer de próstata',
                    hipotiroidismos: 'Hipotiroidismos',
                    hipertension: 'Hipertensión',
                    cancer_mama: 'Cáncer de mama',
                    ins_resistencia: 'Insulino Resistencia',
                    cancer_colon: 'Cáncer de colon',
                    asma: 'Asma',
                    rin_aller: 'Rinitis alérgica',
                    tabaq: 'Tabaquismo',
                    sedent: 'Sedentarismo',
                    obesidad: 'Obesidad',
                    sobreP: 'Sobrepeso',
                    modif_perf: 'Atrás',
                    continuar: 'Continuar',
                    visualizar: 'VISUALIZAR EXÁMENES',
                    guardar: 'Ingresa tus datos',
                    //// Forms
                    required: "es un campo requerido",
                    regis: "Regístrate",
                    signIn: "Registrarse",
                    selone: "Selecciona uno",
                    email: "E-MAIL",
                    plzInfo: "INGRESE SU INFORMACIÓN",
                    name: 'NOMBRE',
                    lastName: 'APELLIDO',
                    birth: 'FECHA DE NACIMIENTO',
                    gender: 'GÉNERO',
                    country: 'PAÍS (Temporalmente fijado a solo Chile)',
                    passwordNew: 'CONTRASEÑA',
                    confPasswordNew: 'CONFIRMAR CONTRASEÑA',
                    mascul: 'Masculino',
                    femen: 'Femenino',
                    other: 'Otro',
                    submitButton: 'VISUALIZAR EXÁMENES',
                    IngRUT: 'Ingrese RUT',
                    corrEl: 'Correo Electrónico',
                    //// Reasons
                    selectExams: 'Seleccione los exámenes que desee realizar',
                    siguiente: 'Generar orden',
                    //// Dolor
                    thanks: 'Tu orden de exámenes ha sido creada con éxito',
                    thanksSub: 'Puedes descargar las órdenes con los botones a continuación',
                    viewPDF: 'Ver PDF',
                    loadingDoc: 'Cargando Documento...',
                    downloadO: 'Descargar Orden',

                    //// Exams Stuff
                    hemograma: 'Hemograma y VHS',
                    cProtein: 'Proteína C Reactiva',
                    pLipidico: 'Perfil Lipídico',
                    pHepatico: 'Perfil Hepático',
                    pBioquimico: 'Perfil Bioquímico',
                    creati: 'Creatinina',
                    electrPlas: 'Electrolitos Plasmáticos',
                    t4: 'T4 Libre',
                    tsh: 'TSH',
                    papaNic: 'Papanicolau (PAP)',
                    ape: 'APE (Antígeno prostático específico) Libre y Total',
                    hemorr: 'Test de hemorragia oculta en deposiciones',
                    ecoMam: 'Ecografía mamaria bilateral',
                    mamoBil: 'Mamografía bilateral',
                    densitOsea: 'Densitometría ósea',
                    electroC: 'Electrocardiograma',
                    vitD: '25-OH Vitamina D',
                    vitB: 'Vitamina B12',
                    testTot: 'Testosterona total',
                    SHBG: 'SHBG',
                    ecoAb: 'Ecografía abdominal',
                    HOMA: 'Índice de HOMA',
                    holter: 'Holter de presión',
                    hemoGlic: 'Hemoglobina Glicosilada',
                    ptgo: 'PTGO (prueba de toleracia glucosa oral)',
                    insBasal: 'Insulina Basal',
                    curvIns: 'Curva de Insulina',
                    orinaC: 'Orina Completa',
                    RAC: 'RAC (Relación albuminuria/creatinuria)',
                    clearCrea: 'Clearence de Creatinina',
                    ecoTiro: 'Ecografía tiroídea',
                    radioTorax: 'Radiografía de Tórax AP-LAT',
                    // Extras Packs
                    ciFi: 'Cinética de Fierro',
                    ferri: 'Ferritina',
                    VDRL: 'VDRL',
                    holterArr: 'Holter de Arritmia',
                    espiroB: 'Espirometría Basal y con Broncodilatador',
                    audiom: 'Audiometría',
                    par8: 'VIII par',
                    antiAntitra: 'Anticuerpos antitransglutaminasa (TTG)',
                    inmunoGlo: 'Inmunoglobulinas IgA, IgG, IgM, c/u',
                    drogasAb: 'Panel Drogas de Abuso',
                    anfeta: 'Anfetaminas',
                    thc: 'THC',
                    cocaina: 'Cocaína',
                    benzo: 'Benzodiacepinas',
                    antiIGG: 'Anticuerpos IGG',
                    antiTot: 'Anticuerpos Totales',
                    magnesio: 'Magnesio',
                    acFol: 'Ácido Fólico',
                    calcio: 'Calcio',
                    fosfato: 'Fosfato de Zinc',
                    urocul: 'Urocultivo',
                    nitroU: 'Nitrógeno Ureico',
                    acUrico: 'Acido Urico',
                    elisaVIH: 'Test Elisa para VIH',
                    elisaVCH: 'Test Elisa VHC',
                    sifilis: 'RPR Plasmático (Sífilis)',
                    hepaB: 'Hepatitis B',
                    PCRni: 'PCR Neisseria',
                    PCRch: 'PCR Chlamydia',
                    mycoP: 'Mycoplasma',
                    elecDia: 'Electrocardiograma',
                    esfuer: 'Test de Esfuerzo',
                    gonad: 'Gonadotrofina Coriónica',
                    // Requirements
                    noPrep: 'No necesita preparación',
                    ayuno: 'Ayuno 8-12hrs',
                    especialC: 'Consultar indicaciones especiales',

                }
            },
            en: {
                translation: {
                    //// Login
                    login: 'LOGIN',
                    pleaseLogin: 'Please enter your password',
                    password: 'Password',
                    //barSup
                    inicio: 'MAIN',
                    procesos: 'PROCESSES',
                    beneficios: 'BENEFITS',
                    costos: 'PRICES',
                    esp: 'Spanish',
                    ing: 'English',
                    port: 'Portuguese',
                    flags: ingl,
                    //HUGEMAN
                    no_filas: "An app that allows you to take smart control of your health",
                    HugemanSubtext: 'Our digital health platform accompanies you in your care through data analysis (AI) and predictive medicine, so that all those who want to live an active and healthy life in Chile and Latin America can act to prevent illness or premature death by taking action on time.',
                    generate: 'Generate Health Predictive Analysis',
                    MANLY: MAN_ENG,
                    //PREVIEW
                    preview_helps_us: 'Help us build the future of personal and family healthcare',
                    preview_help_us_description: 'Thanks for your interest in YMC, we are still in the launch process. Visit us in the next few days and you will see what we are talking about.',
                    //Como
                    how: 'How does Yo Me Controlo work?',
                    head1: 'START',
                    head2: 'PAY ONLINE',
                    head3: 'SUGESTIONS',
                    card1: 'With our virtual Assistant',
                    card2: 'And get your Order much quicker',
                    card3: 'We suggest you where to take your exams',
                    //AyD
                    ayd: 'Before and After',
                    SD: 'WITHOUT YOMECONTROLO',
                    CD: 'WITH YOMECONTROLO',
                    T1: 'Book a meeting with a medic',
                    T2: 'Transfer to the nearest Health Center',
                    T3: 'Pay a fee to get attended',
                    T4: 'Interview with your medic so to get an order to take exams',
                    T5: 'Login to yomecontrolo.cl',
                    T6: 'Start the virtual assistant',
                    T7: 'Yomecontrolo suggests you which exams to take according to your profile',
                    T8: 'Pay and download your order from the page',
                    bigtext: 'Cheaper and quicker!',
                    bigtext2: 'Plus, we suggest you laboratories near you to go take your exams',
                    //Cards
                    beforeYouGo: 'Before Continuing',
                    choosePacks: 'Would you like to add packs to your medical order?',
                    access: 'Quickly access an exam pack',
                    wanted: 'Most wanted exams, no requirements needed to order them',
                    pack1: 'CAPILLARY PACK',
                    pack2: 'OCCUPATIONAL HEALTH',
                    pack3: 'CELIAC PACK',
                    pack4: 'DRUGS EVALUATION',
                    pack5: 'COVID ANTIGEN',
                    pack6: 'NUTRITIONAL CONDITION',
                    pack7: 'VEGAN/VEGETARIAN',
                    pack8: 'SEXUAL TRANSMISSION DISEASE DETECTION',
                    pack9: 'FULL CARDIOVASCULAR CHECKUP',
                    pack10: 'RENAL PROFILE AND URINE TEST',
                    pack11: 'PREPARTICIPATION PHYSICAL EXAMINATIONS',
                    pack12: 'SANGUINE PREGNANCY EXAM',
                    ex1: 'BLOOD EXAM',
                    ex2: 'PCR ANTIGEN',
                    ex3: 'ABSOLUTE LYMPHOCYTES COUNT',
                    ex4: 'GLOMERULAL FILTRATION RATE',
                    selecAll: 'SELECT ALL',
                    r_less: 'Read Less',
                    r_more: 'Read More',
                    backButton: 'BACK',
                    //Footer
                    MORE: 'READ MORE',
                    contacto: 'CONTACT',
                    idioma: 'LANGUAGE',
                    about: 'About',
                    policy: 'Our Policies',
                    tos: 'Terms and Conditions',
                    dir: 'Condell Avenue 1015, Providencia Metropolitan Region',
                    finale: 'yomecontrolo.cl 2023 All rights reserved',
                    //// MedicalHistory
                    ant_familiares: 'MODIFY YOUR FAMILY MEDICAL HISTORY',
                    fam_subtext: '',
                    per_subtext: 'Filling up this information will help us a lot',
                    ant_personales: 'MODIFY YOUR PERSONAL MEDICAL HISTORY',
                    diabetes: 'Diabetes',
                    cancer_prostata: 'Prostate Cancer',
                    hipotiroidismos: 'Hypothyroidism',
                    hipertension: 'Hypertension',
                    cancer_mama: 'Breast Cancer',
                    ins_resistencia: 'Insulin Resistance',
                    cancer_colon: 'Colon Cancer',
                    asma: 'Asthma',
                    rin_aller: 'Allergic Rhinitis',
                    tabaq: 'Smoking',
                    sedent: 'Sedentary Lifestyle',
                    obesidad: 'Obesity',
                    sobreP: 'Overweight',
                    modif_perf: 'BACK',
                    continuar: 'Continue',
                    visualizar: 'VIEW EXAMS',
                    guardar: 'SAVE',
                    //// Forms
                    required: "is required",
                    regis: "Register Now",
                    signIn: "Register",
                    selone: "Select one",
                    email: "EMAIL",
                    plzInfo: "FILL IN YOUR INFORMATION",
                    name: 'FIRST NAME',
                    lastName: 'LAST NAME',
                    birth: 'BIRTH DATE',
                    gender: 'GENDER',
                    country: 'COUNTRY (Temporarily fixed to Chile)',
                    passwordNew: 'PASSWORD',
                    confPasswordNew: 'CONFIRM PASSWORD',
                    mascul: 'Masculine',
                    femen: 'Femenine',
                    other: 'Other',
                    submitButton: 'SUBMIT',
                    IngRUT: 'Enter RUT',
                    corrEl: 'Email',
                    //// Reasons
                    selectExams: 'Select Which Exams you Wish to Take',
                    siguiente: 'NEXT',
                    //// Dolor
                    thanks: 'Tu orden de exámenes ha sido creada con éxito',
                    thanksSub: 'Puedes descargar las órdenes con los botones a continuación',
                    viewPDF: 'See PDF',
                    loadingDoc: 'Loading Document...',
                    downloadO: 'Download Order',

                    //// Exams Stuff
                    hemograma: 'CBC and VHS',
                    cProtein: 'C Reactive Protein',
                    pLipidico: 'Lipidic Profile',
                    pHepatico: 'Liver Profile',
                    pBioquimico: 'Biochemical Profile',
                    creati: 'Creatinine',
                    electrPlas: 'Plasma Electrolytes',
                    t4: 'Free T4',
                    tsh: 'TSH',
                    papaNic: 'Pap smear (PAP)',
                    ape: 'PSA (Prostate Specific Antigen) Free and Total',
                    hemorr: 'Occult stool bleeding test',
                    ecoMam: 'Bilateral breast ultrasound',
                    mamoBil: 'Bilateral mammography',
                    densitOsea: 'Bone Densitometry',
                    electroC: 'Electrocardiogram',
                    vitD: '25-OH Vitamin D',
                    vitB: 'Vitamin B12',
                    testTot: 'Total Testosterone',
                    SHBG: 'SHBG',
                    ecoAb: 'Abdominal Ultrasound',
                    HOMA: 'HOMA index',
                    holter: 'Pressure Holter',
                    hemoGlic: 'Glycosylated hemoglobin',
                    ptgo: 'OGTT (glucose oral tolerance test)',
                    insBasal: 'Basal Insulin',
                    curvIns: 'Insulin Curve',
                    orinaC: 'Complete Urine',
                    RAC: 'ACR (Albumin/Creatinine Ratio)',
                    clearCrea: 'Creatinine Clearance',
                    ecoTiro: 'Thyroid Ultrasound',
                    radioTorax: 'Chest X-ray AP-LAT',
                    // Extras Packs
                    ciFi: 'Iron kinetics',
                    ferri: 'Ferritin',
                    VDRL: 'VDRL',
                    holterArr: 'Arrhythmian Holter',
                    espiroB: 'Basal and Bronchodilator Spirometry',
                    audiom: 'Audiometry',
                    par8: 'VIII pair',
                    antiAntitra: 'Anti-transglutaminase Antibodies (TTGA)',
                    inmunoGlo: 'Inmunoglobulines IgA, IgG, IgM, c/u',
                    drogasAb: 'Drug Abuse Panel',
                    anfeta: 'Anfetamines',
                    thc: 'THC',
                    cocaina: 'Cocaine',
                    benzo: 'Benzodiazepines',
                    antiIGG: 'IGG Antibodies',
                    antiTot: 'Total Antibodies',
                    magnesio: 'Magnesium',
                    acFol: 'Folic Acid',
                    calcio: 'Calcium',
                    fosfato: 'Zinc phosphate',
                    urocul: 'Urine Culture',
                    nitroU: 'Ureic Nitrogen',
                    acUrico: 'Acido Urico',
                    elisaVIH: 'Elisa Test for HIV',
                    elisaVCH: 'Elisa Test for VHC',
                    sifilis: 'Plasma RPR (Syphilis)',
                    hepaB: 'Hepatitis B',
                    PCRni: 'Neisseria PCR',
                    PCRch: 'Chlamydia PCR',
                    mycoP: 'Mycoplasm',
                    elecDia: 'Electrocardiogram',
                    esfuer: 'Effort Test',
                    gonad: 'Chorionic Gonadotropin',
                    // Requirements
                    noPrep: 'No preparation needed',
                    ayuno: 'Fast 8-12hrs',
                    especialC: 'Consult special indications',

                }
            },
            pt: {
                translation: {
                    //// Login
                    login: 'CONECTE-SE',
                    pleaseLogin: 'Por favor, insira sua senha',
                    password: 'Senha',
                    //barSup
                    inicio: 'COMEÇO',
                    procesos: 'PROCESSOS',
                    beneficios: 'BENEFICIOS',
                    costos: 'CUSTOS',
                    esp: 'Espanhol',
                    ing: 'Inglês',
                    port: 'Português',
                    flags: port,
                    //HUGEMAN
                    no_filas: "Um aplicativo que permite que você assuma o controle inteligente de sua saúde",
                    HugemanSubtext: 'Nossa plataforma digital de saúde acompanha você em seus cuidados por meio de análise de dados (IA) e medicina preditiva, para que todos aqueles que desejam viver uma vida ativa e saudável no Chile e na América Latina possam agir para prevenir doenças ou morte prematura agindo a tempo .',
                    generate: 'Faça Análise Preditiva de Saúde',
                    MANLY: MAN_PT,
                    //PREVIEW
                    preview_helps_us: 'Ajude-nos a construir o futuro da saúde pessoal e familiar',
                    preview_help_us_description: 'Obrigado pelo seu interesse no YMC, ainda estamos no processo de lançamento. Visite-nos nos próximos dias e verá do que estamos a falar.',
                    //Como
                    how: 'Como o Yo Me Controlo funciona?',
                    head1: 'COMEÇAR',
                    head2: 'PAGAR ONLINE',
                    head3: 'SUGESTÕES',
                    card1: 'Com nosso Assistente virtual',
                    card2: 'E receba seu pedido rapidamente',
                    card3: 'Sugerimos-lhe onde fazer os seus exames',
                    //AyD
                    ayd: 'Antes e Depois',
                    SD: 'SEM YOMECONTROLO',
                    CD: 'COM YOMECONTROLO',
                    T1: 'Marque uma reunião com um médico',
                    T2: 'Mover para o Centro de Saúde',
                    T3: 'Pague uma taxa para ser atendido',
                    T4: 'Entrevista com seu médico para obter uma ordem para fazer exames',
                    T5: 'Logar em yomecontrolo.cl',
                    T6: 'Iniciar o assistente virtual',
                    T7: 'Yomecontrolo sugere quais exames fazer de acordo com o seu perfil',
                    T8: 'Pague e baixe seu pedido na página',
                    bigtext: '¡Mais barato e mais rápido!',
                    bigtext2: 'Além disso, sugerimos aos laboratórios próximos a sua casa para que você possa fazer seus exames',
                    //Cards
                    beforeYouGo: 'Antes de Continuar',
                    choosePacks: 'Gostaria de adicionar alguns pacotes ao seu pedido médico?',
                    access: 'Acesse rapidamente um pacote de exames',
                    wanted: 'Exames mais procurados, sem requisitos necessários para solicitá-los',
                    pack1: 'PACOTE CAPILAR',
                    pack2: 'SAÚDE OCUPACIONAL',
                    pack3: 'PACOTE CELÍACO',
                    pack4: 'AVALIAÇÃO DE DROGAS',
                    pack5: 'ANTICORPOS COVID',
                    pack6: 'CONDIÇÃO NUTRICIONAL',
                    pack7: 'VEGANO/VEGETARIANO',
                    pack8: 'DETECÇÃO DE DOENÇAS SEXUALMENTE TRANSMISSÍVEIS',
                    pack9: 'CHECK-UP CARDIOVASCULAR COMPLETO',
                    pack10: 'PERFIL RENAL E EXAME DE URINA',
                    pack11: 'AVALIAÇÃO PRÉ-DESPORTIVA',
                    pack12: 'EXAME GREVIDEZ SANGÜÍNEO',
                    ex1: 'EXAME DE SANGUE',
                    ex2: 'ANTÍGEN PCR',
                    ex3: 'CONTAGEM ABSOLUTA DE LINFÓCITOS',
                    ex4: 'TAXA DE FILTRAÇÃO GLOMERULAR',
                    selecAll: 'SELECIONAR TUDO',
                    r_less: 'Ver Menos',
                    r_more: 'Ver Mais',
                    visualizar: 'VISUALIZAR EXÁMENES',
                    backButton: 'VOLTAR',
                    //Footer
                    MORE: 'MAIS INFORMAÇÃO',
                    contacto: 'CONTATO',
                    idioma: 'LINGUAGEM',
                    about: 'Sobre Nós',
                    policy: 'Nossas Politicas',
                    tos: 'Termos e Condições',
                    dir: 'Condell Avenue 1015, Providencia Região Metropolitana',
                    finale: 'yomecontrolo.cl 2023 Todos os direitos reservados',
                    //// MedicalHistory
                    ant_familiares: 'MODIFIQUE SUA ANTECEDENTE FAMILIAR',
                    fam_subtext: '',
                    per_subtext: 'Preencher essas informações nos ajudará muito',
                    ant_personales: 'MODIFIQUE SEU ANTECEDENTE PESSOAL',
                    diabetes: 'Diabetes',
                    cancer_prostata: 'Câncer de Próstata',
                    hipotiroidismos: 'Hipotireoidismo',
                    hipertension: 'Hipertensão',
                    cancer_mama: 'Câncer de mama',
                    ins_resistencia: 'Resistência à Insulina',
                    cancer_colon: 'Cancer de colo',
                    asma: 'Asma',
                    rin_aller: 'Rinite alérgica',
                    tabaq: 'Fumar',
                    sedent: 'Estilo de vida sedentário',
                    obesidad: 'Obesidade',
                    sobreP: 'Sobrepeso',
                    modif_perf: 'VOLTAR',
                    continuar: 'Continuar',
                    guardar: 'GUARDAR',
                    //// Forms
                    required: "é necessário",
                    regis: "Registrar Agora",
                    signIn: "Registro",
                    selone: "Selecione um",
                    email: "CORREIO ELETRÔNICO",
                    plzInfo: "INSIRA SUAS INFORMAÇÕES",
                    name: 'NOME',
                    lastName: 'SOBRENOME',
                    birth: 'DATA DE NASCIMENTO',
                    gender: 'GÊNERO',
                    country: 'PAÍS (temporariamente fixado no Chile)',
                    passwordNew: 'SENHA',
                    confPasswordNew: 'CONFIRME SUA SENHA',
                    mascul: 'Macho',
                    femen: 'Fêmea',
                    other: 'Outro',
                    submitButton: 'ENVIAR',
                    IngRUT: 'Digitar RUT',
                    corrEl: 'Correio eletrônico',
                    //// Reasons
                    selectExams: 'Selecione os Exames que Deseja fazer',
                    siguiente: 'SEGUINDO',
                    //// Dolor
                    thanks: 'Tu orden de exámenes ha sido creada con éxito',
                    thanksSub: 'Puedes descargar las órdenes con los botones a continuación',
                    viewPDF: 'Ver PDF',
                    loadingDoc: 'Carregando Documento...',
                    downloadO: 'Baixar Pedido',

                    //// Exams Stuff
                    radioTorax: 'Radiografia de tórax AP-LAT',
                    // Requirements
                    noPrep: 'Não há necessidade de preparação',
                    ayuno: 'Rápido 8-12hrs',
                    especialC: 'Consulte indicações especiais',
                }
            }
        }
    })