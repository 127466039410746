import React from 'react';
import "./Styles/MedicalHistoryRoute.css";
import NavBar from '../NavBar';
import Footer from '../Footer';
import MedicalHistoryForm from './Components/MedicalHistoryRoute/MedicalHistoryForm';
import MedicalHistoryRouteTitle from './Components/MedicalHistoryRoute/MedicalHistoryTitle';
import UserInfoProvider from './Components/Context/UserInfoContext';
import PacksProvider from './Components/Context/PacksContext';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function MedicalHistoryRoute() {
    return (
        <UserInfoProvider>
            <PacksProvider>
            <BrowserView>
        <div className="MedicalHistoryRoutePage">
            <div className="MedicalHistoryRouteNavBar">      
                    <NavBar/>
                 </div>
            <div className="MedicalHistoryRouteTitle">
                <MedicalHistoryRouteTitle/>    
            </div>
            <div className="MedicalHistoryRouteForm">
                <MedicalHistoryForm/>
            </div>
            <div className="MedicalHistoryRouteFooter">
                <Footer/>
            </div>
        </div>
        </BrowserView>
        <MobileView>
        <div className="MedicalHistoryRoutePage">
            <div className="MedicalHistoryRouteNavBar">      
                    <NavBar/>
                 </div>
            <div className="MedicalHistoryRouteTitle">
                <MedicalHistoryRouteTitle/>    
            </div>
            <div className="MedicalHistoryRouteForm">
                <MedicalHistoryForm/>
            </div>
            <div className="MedicalHistoryRouteFooter">
                <Footer/>
            </div>
        </div>
        </MobileView>
        </PacksProvider>
        </UserInfoProvider>
    );
}

export default MedicalHistoryRoute;