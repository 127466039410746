import React, {useState} from 'react';
//import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import TinyAttachmentIcon from "../../Photos/TinyAttachmentIcon.png"
import LazyLoad from 'react-lazyload';

const Newsletter = () => {

    const [FullName,setFullName] = useState("")
    const [Email,setEmail] = useState("")
    const [Validation, setValidation] = useState({
        "Name": "valid",
        "Email": "valid"
    });
    const [TaskStart,setTaskStart] = useState(false)
    const [TaskDone,setTaskDone] = useState(false)
    const [TOSMissing,setTOSMissing] = useState(false)
    const Background = document.URL.includes("contacto") || document.URL.includes("edad-del-corazon")

    const sendEbook = async() => {
        setTaskStart(true)
    
        var params = {
          email: Email,
          name: FullName
        };
      
        var requestOptions = {
          method: 'POST',
          redirect: 'follow',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params)
        };
    
        try{
          
        const resp = await fetch("https://ymc-doc-api.azurewebsites.net/sendebookmail", requestOptions)
         if (resp.status !== 200){
          alert("there has been some error");
          return false;
         }
    
         setTaskDone(true)
         setTaskStart(false)
        }
        catch(error){
          //console.log("there has been an error login in")
          alert("Ocurrió un error procesando los resultados")
          return false
        }
    }

    function ValidateContact() {
        let accepted = document.getElementById('TOS')

        let validRegexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        let isEmailValid = "valid"
        if(!Email.match(validRegexEmail)) {
            isEmailValid = "invalid"
        } 
        let isNameValid = Object.keys(FullName).length === 0 ? "invalid" : FullName.trim() === "" ? "invalid" : "valid";

        setValidation({
            "Name": isNameValid,
            "Email": isEmailValid
        });

        if(isNameValid === "valid" & isEmailValid === "valid") {
            if(accepted.checked) {
                setTOSMissing(false)
                sendEbook()
            } else {
                setTOSMissing(true)
            }
        } else {
            setTOSMissing(false)
        }
    }

    return (
        <div className='ContactPageNewsletter' style={Background ? {backgroundColor: "#330066"} : {backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundHueYMC.png')",backgroundPosition: 'center',backgroundSize: '100% 120%',backgroundRepeat: 'no-repeat'}}>
            <div className='ContactPageNewsletterContent' style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/Background-ebook.png')",backgroundPosition: 'center',backgroundSize: '100% 120%',backgroundRepeat: 'no-repeat'}}>
                <div className='ContactPageNewsletterLeftSide'>
                    <div className='ContactPageNewsletterUpTitle'>E-book gratuito</div>
                    <div className='ContactPageNewsletterTitle'>¿Quieres saber cómo puedes empezar a cuidarte mejor?</div>
                    <div className='ContactPageNewsletterSubtitle'>Recibe nuestro e-book “guía del autocuidado” especialmente hecho para ayudar a personas como tú en su camino a un mañana mejor.</div>
                    <br/>
                    <div className='ContactPageNewsletterSubtitle'>Inscribe tu nombre e email y obtén tu e-book ahora en tu correo electrónico, más un código de 50% de descuento en tu primera compra.</div>
                    <div className='ContactPageNewsletterTOSFlex'>
                        <input type="checkbox" name="acceptTOS" className="ExamRecommendationBasketTOSCheck" id="TOS" />&ensp;
                        <div className='ContactPageNewsletterTOS'>Acepto los <a className='ExamRecommendationBasketEmailInfoLink' href="https://static.yomecontrolo.cl/public/pdf/GENERAL-TERMS-YMC.pdf" target="_blank">términos y condiciones</a>.</div>
                    </div>
                    <div className='ContactPageNewsletterInputFlex'>
                        <input
                            value={FullName}
                            className="ContactPageNewsletterInputTile"
                            style={{ border: Validation["Name"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                            id="YMCFullName"
                            maxLength={70}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Nombre Apellido" />
                        <input
                            value={Email}
                            className="ContactPageNewsletterInputTile"
                            style={{ border: Validation["Email"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                            id="YMCEmail"
                            type="email"
                            maxLength={60}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email" />
                        {TaskStart & !TaskDone ?
                        <div className="ContactPageNewsletterDownloadButton">Enviando...
                            <img className='ContactPageNewsletterDownloadIcon' src={TinyAttachmentIcon}/>
                        </div>
                        : TaskDone ?
                        <div className="ContactPageNewsletterDownloadButtonDisabled">Enviado
                            <img className='ContactPageNewsletterDownloadIcon' src={TinyAttachmentIcon}/>
                        </div>
                        :
                        <div className="ContactPageNewsletterDownloadButton" onClick={() => ValidateContact()}>Obtener
                            <img className='ContactPageNewsletterDownloadIcon' src={TinyAttachmentIcon}/>
                        </div>
                        }
                    </div>
                    {Object.values(Validation).indexOf('invalid') > -1 ?
                        <div className="ContactPageNewsletterDownloadInputFail">Input inválido, revise los campos marcados</div>
                    : null}
                    {TOSMissing ?
                        <div className='ExamRecommendationBasketTOSAlert'>Por favor aceptar términos y condiciones</div>
                    : null}
                </div>
                <div className='ContactPageNewsletterRightSide'>
                    <LazyLoad once >
                    <img className='ContactPageNewsletterRightImage' src="https://static.yomecontrolo.cl/public/img/home/ebook-tablet.png"/>
                    </LazyLoad>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
