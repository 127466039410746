import React, { useContext, useState, useEffect} from 'react';
import "../../Styles/ExamRecommendationPacks.css";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import PackRectangle from "../../Photos/PackRectangle.png"
import packslist from "../../../JSONS/updatedPacks.json"
import packsSponsored from "../../../JSONS/sponsoredPack.json"
import { Packs } from '../Context/PacksContext';


function ExamRecommendationPacks() {
    const packsKeys = Object.keys(packslist)
    const Navigate = useNavigate();

    const {
        AllPacks, BasketPacks
    } = useContext(Packs);

    const [VisualizePack,SetVisualizePack] = useState("")

    const [ChosenToSee, setChosenToSee] = useState(false);
    const [Page, setPage] = useState(1);
    const [PacksShown,setPacksShown] = useState(window.innerWidth <= 620 ? 1 : window.innerWidth <= 920 ? 2 : 3)

    useEffect(() => {
        if(ChosenToSee) {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
            Navigate("/detalles-de-pack?pack=".concat(VisualizePack))
        }
    });

    //const packNames = AllPacks.map(a => a.name);

    const packRecs = () => {
        let recoms = []
        
        for(let i=0; i<BasketPacks.length; i++) {
            if(packslist[BasketPacks[i]]) {
                if(packslist[BasketPacks[i]].recommended) {
                    recoms.push(...packslist[BasketPacks[i]].recommended)
                }
            }
        }

        recoms.push(...AllPacks)

        return [...new Set(recoms)].filter(a => !BasketPacks.includes(a))
    }

    function toggleDescription(id,type) {
        let tmp = document.getElementById(id.concat("More"))
        let tmp2 = document.getElementById(id.concat("Less"))
        if(type === "More") {
            tmp.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
            tmp2.className = tmp2.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
        } else {
            tmp.className = tmp.className.replace(" ExamsByPacksPackBoxWhiteBoxDescriptionHidden", "");
            tmp2.className += " ExamsByPacksPackBoxWhiteBoxDescriptionHidden"
        }
      }

    function ChangeNumberOfPacks() {
        let current = PacksShown
        let different = false
    
        if(window.innerWidth <= 620) {
          setPacksShown(1)
          if(current != 1) different = true
        } else if(window.innerWidth <= 920) {
          setPacksShown(2)
          if(current != 2) different = true
        } else {
          setPacksShown(3)
          if(current != 3) different = true
        }
    
        if(different) {
          setPage(1)
        }
      }
    
      useEffect(() => {
        window.addEventListener("resize", ChangeNumberOfPacks);
        return () => window.removeEventListener("resize", ChangeNumberOfPacks);
      }, [ChangeNumberOfPacks]);
    
    
    return (
        <>
        {packRecs().length === 0 ?
        null
        :
            <div className="ExamRecommendationRoutePacksDiv">
                <div className="ExamRecommendationRoutePacksUpTitle">Otros exámenes relacionados</div>
                <div className="ExamRecommendationRoutePacksTitle">Complementa tu chequeo con alguna de estas sugerencias</div>
                <div className="ExamRecommendationRoutePacksPackBoxesFullLineFlex">
                    {Page === 1 ?
                    null :
                    <div className='ExamRecommendationRoutePacksLeftArrowDiv' onClick={() => setPage(Page-1)}>
                    <FontAwesomeIcon 
                        icon={faArrowRightLong} 
                        className='ExamRecommendationRoutePacksLeftArrow text-end fa-rotate-180'
                    />
                    </div>
                    }
                    <div className="ExamRecommendationRoutePacksPackBoxesFlex">
                        {packRecs().slice(PacksShown*(Page-1),PacksShown*Page).map((pack) => (
                        <div className="ExamRecommendationRoutePacksPackBox" key={pack}>
                            <div className='ExamsByPacksPackBoxBackImage' style={{backgroundImage:"linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 50%),url('https://static.yomecontrolo.cl/public/img/packs/"+ pack +".png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}></div>
                                <div className="ExamRecommendationRoutePacksPackBoxWhiteBox">
                                <div className="ExamRecommendationRoutePacksPackBoxWhiteBoxTitle">{packslist[pack] ? packslist[pack].name : packsSponsored[pack].name}</div>
                                <div className="ExamsByPacksPackBoxWhiteBoxDescription" id={pack.concat("More")}>{packslist[pack] ? packslist[pack].description.split(' ').slice(0,12).join(' ') : packsSponsored[pack].description.split(' ').slice(0,12).join(' ')} &nbsp;
                                    <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"More")}>Leer más...</span>
                                </div>
                                <div className="ExamsByPacksPackBoxWhiteBoxDescription ExamsByPacksPackBoxWhiteBoxDescriptionHidden" id={pack.concat("Less")}>{packslist[pack] ? packslist[pack].description : packsSponsored[pack].description} &nbsp;
                                    <span className='ExamsByPacksPackBoxWhiteBoxTextOption' onClick={() => toggleDescription(pack,"Less")}>Leer menos...</span>
                                </div>
                                <div className="ExamRecommendationRoutePacksPackBoxWhiteBoxFlex">
                                    <div className="ExamRecommendationRoutePacksPackBoxWhiteBoxPrice">${packslist[pack] ? packslist[pack].price : packsSponsored[pack].price}</div>
                                </div>
                                <div className="ExamRecommendationRoutePacksPackBoxWhiteBoxMoreInfoButton" onClick={() => {SetVisualizePack(pack); setChosenToSee(true);}}>Más Información</div>
                            </div>
                        </div>
                        ))}
                    </div>

                    {Page === Math.ceil(packRecs().length / PacksShown) ?
                    null :
                    <div className='ExamRecommendationRoutePacksRightArrowDiv' onClick={() => setPage(Page+1)}>
                    <FontAwesomeIcon 
                        icon={faArrowRightLong} 
                        className='ExamRecommendationRoutePacksRightArrow text-end'
                    />
                    </div>
                    }
                </div>
            </div>
}
        </>
    );
} 

export default ExamRecommendationPacks;