import React, { useState, useEffect, useContext, useRef } from 'react';
import "../../Styles/HeartAgeCalculator.css";
import { useNavigate } from 'react-router-dom';
import HeartAgeCalc from './HeartAgeCode'
import { Packs } from '../Context/PacksContext';
import { UserInfo } from "../Context/UserInfoContext";
import Newsletter from "../ContactRoute/Newsletter"
import packslist from "../../../JSONS/updatedPacks.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function HeartAgeCalculator() {
  const Navigate = useNavigate();

  const calculatedRef = useRef(null)
  const executeScroll = () => calculatedRef.current.scrollIntoView()

  const recalculateRef = useRef(null)
  const executeScroll2 = () => recalculateRef.current.scrollIntoView()

  const [ArrowUp, setArrowUp] = useState(false)
  const [Gender,setGender] = useState("M")
  const [Age,setAge] = useState(40)
  const [Smoke,setSmoke] = useState("NO")
  const [Sistolic,setSistolic] = useState("")
  const [Choles,setCholes] = useState("")
  const [Email,setEmail] = useState("")
  const [Calculated, setCalculated] = useState(false)
  const [Risk, setRisk] = useState(0)
  const [Risk10, setRisk10] = useState(0)
  const [TmpAge,setTmpAge] = useState(40)
  const [OneTime, setOneTime] = useState(false)
  const [ToLipidic, setToLipidic] = useState(false)
  const [TaskStart, setTaskStart] = useState(false)
  const [TaskDone, setTaskDone] = useState(false)
  const [WOSmoke,setWOSmoke] = useState(-1)
  const [RegCholes,setRegCholes] = useState(-1)
  const [RegSistolic,setRegSistolic] = useState(-1)

  const {
    HeartAgeCalculated, setHeartAgeCalculated, Weight, setWeight, Height, setHeight, Recent, setRecent
  } = useContext(UserInfo);

  useEffect(() => {
    if(ToLipidic) {
        window.scrollTo(0, 0);
        Navigate("/detalles-de-pack?pack=PM00YMC041")
    }
  })

  const [validation, setvalidation] = useState({
    "Blood": "valid",
    "Choles": "valid",
    "Weight": "valid",
    "Height": "valid"
  });

  const [validation2, setvalidation2] = useState({
    "Email": "valid"
  });

  function GenderChange(sex) {
    let tmp1 = document.getElementById("MaleTile")
    let tmp1I = document.getElementById("MaleTileIcon")
    let tmp2 = document.getElementById("FemaleTile")
    let tmp2I = document.getElementById("FemaleTileIcon")
    if(tmp2.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile") & sex !== "F") {
        tmp2.className = tmp2.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
        tmp2I.className = tmp2I.className.replace(" HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon","")
        tmp1.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        tmp1I.className += " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender("M")
    }
    if(tmp1.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile") & sex !== "M") {
        tmp1.className = tmp1.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
        tmp1I.className = tmp1I.className.replace(" HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon","")
        tmp2.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        tmp2I.className += " HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon"
        setGender("F")
    }
  }

  function SmokeChange(ans) {
    let tmp1 = document.getElementById("NoSmokeTile")
    let tmp2 = document.getElementById("SmokeTile")
    if(tmp2.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile") & ans === "NO") {
        tmp2.className = tmp2.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
        tmp1.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        setSmoke("NO")
    }
    if(tmp1.className.includes("HeartAgeCalculatorRightSideSurveyQ1ActiveTile") & ans === "YES") {
        tmp1.className = tmp1.className.replace(" HeartAgeCalculatorRightSideSurveyQ1ActiveTile","")
        tmp2.className += " HeartAgeCalculatorRightSideSurveyQ1ActiveTile"
        setSmoke("YES")
    }
  }

  const handleChange = (value, setValue) => {
    const result = value.replace(/\D/g, '');
    setValue(result.slice(0, 3));
  };

  function HandleSubmit() {

    const isCholesValid = Choles.trim() === "" ? "invalid" : Choles >= 550 ? "invalid" : "valid";
    const isBloodValid = Sistolic.trim() === "" ? "invalid" : Sistolic < 40 ? "invalid" : Sistolic > 370 ? "invalid" : "valid";
    const isWeightValid = Weight.trim() === "" ? "invalid" : "valid";
    const isHeightValid = Height.trim() === "" ? "invalid" : "valid";

    setvalidation({
        "Blood": isBloodValid,
        "Choles": isCholesValid,
        "Weight": isWeightValid,
        "Height": isHeightValid
    });

    if(isBloodValid === "valid" && isCholesValid === "valid" && isWeightValid === "valid" && isHeightValid === "valid") {
        getHeartAge()
    }
  }

  async function getHeartAge() {
    let res = await HeartAgeCalc(Gender, Age, Smoke, Sistolic, Choles)
    setRisk(res)
    let res10 = await HeartAgeCalc(Gender, Age+10, Smoke, Sistolic, Choles)
    setRisk10(res10)
    //console.log(res)
    setTmpAge(Age)

    if(Smoke === "YES") {
        let tmp = await HeartAgeCalc(Gender, Age, "NO", Sistolic, Choles)
        setWOSmoke(tmp.heartAge)
    } else setWOSmoke(-1)

    if(Sistolic > 120) {
        let tmp = await HeartAgeCalc(Gender, Age, Smoke, 120, Choles)
        setRegSistolic(tmp.heartAge)
    } else setRegSistolic(-1)
    
    if(Choles > 154) {
        let tmp = await HeartAgeCalc(Gender, Age, Smoke, Sistolic, 154)
        setRegCholes(tmp.heartAge)
    } else setRegCholes(-1)

    setCalculated(true)
    setOneTime(true)
    if(!HeartAgeCalculated){
        uploadHeartAge(res.heartAge)
        setHeartAgeCalculated(true)
    }
  }

  useEffect(() => {
    if(OneTime) {
        executeScroll()
        setOneTime(false)
    }
  })

    function toggleAdditional(id) {
        let tmp = document.getElementById(id)
        if(tmp.className.includes("InformationCollapsed")) {
            tmp.className = tmp.className.replace(" InformationCollapsed", "");
        } else {
            tmp.className += " InformationCollapsed"
        }
    }

    const uploadHeartAge = async(res) => {
        setTaskStart(true)
    
        var params = {
            gender: Gender,
            age: Age,
            smoke: Smoke,
            systolic: parseInt(Sistolic),
            choles: parseInt(Choles),
            height: parseInt(Height),
            weight: parseInt(Weight),
            heart_age: res,
        };
      
        var requestOptions = {
          method: 'POST',
          redirect: 'follow',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params)
        };
    
        try{
          
        const resp = await fetch("https://ymc-doc-api.azurewebsites.net/heartageregister", requestOptions)
         if (resp.status !== 200){
          alert("there has been some error");
          return false;
         }
    
         setTaskDone(true)
         setTaskStart(false)
        }
        catch(error){
          //console.log("there has been an error login in")
          alert("Ocurrió un error procesando los resultados")
          setTaskStart(false)
          return false
        }
    }

    function displayBiblio() {
        let tmp1 = document.getElementById('BiblioContent')
        if(tmp1.className.includes('HeartAgeCalculatorBibliographyContentHidden')) {
            tmp1.className = tmp1.className.replace(" HeartAgeCalculatorBibliographyContentHidden", "");
            setArrowUp(true)
        } else {
            tmp1.className += " HeartAgeCalculatorBibliographyContentHidden"
            setArrowUp(false)
        }
    }

    function toggleDescription(type) {
        let tmp = document.getElementById("More")
        let tmp2 = document.getElementById("Less")
        if(type === "Less") {
            tmp.className += " HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden"
            tmp2.className = tmp2.className.replace(" HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden", "");
        } else {
            tmp.className = tmp.className.replace(" HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden", "");
            tmp2.className += " HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden"
        }
    }
  
  return (
    <div className="HeartAgeCalculatorFull" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/home/BackgroundOneCorner.png')",backgroundPosition: 'left',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
        <div className="HeartAgeCalculatorInnerDiv">
            <div className="HeartAgeCalculatorLeftSide">
                <div className="HeartAgeCalculatorLeftSideUpTitle">¿Estoy en riesgo cardiovascular?</div>
                <h2 className="HeartAgeCalculatorLeftSideTitle">Conoce la edad de tu corazón y el riesgo cardiovascular.</h2>
                <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence" id="More">La edad del corazón (o edad vascular) se define como la edad que tendría una persona del mismo sexo que tú con los factores de riesgo cardiovascular tratados y controlados. Eso quiere decir que <span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("Less")}>Leer más...</span></div>
                <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden" id="Less">La edad del corazón (o edad vascular) se define como la edad que tendría una persona del mismo sexo que tú con los factores de riesgo cardiovascular tratados y controlados. Eso quiere decir que el <span style={{fontWeight: '900'}}>riesgo de sufrir un evento cardiovascular sería equivalente al de una persona sana de esa edad</span>.
                <br/>El colesterol LDL elevado, la diabetes, el tabaquismo y la hipertensión son los principales factores de riesgo cardiovascular y pueden provocar el desarrollo de enfermedades como el infarto de miocardio, la enfermedad arterial periférica, el ictus isquémico, la estenosis de carótida o un aneurisma de aorta. Su aparición determina el llamado riesgo cardiovascular (CV), que es la probabilidad que tiene una persona de desarrollar una enfermedad cardiovascular.
                <br/><span className='HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption' onClick={() => toggleDescription("More")}>Leer menos...</span></div>
                <img className="HeartAgeCalculatorLeftSideImage" src="https://static.yomecontrolo.cl/public/img/home/HeartAgeRealHeart.png"/>
                <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence">Este instrumento está indicado para personas con edades comprendidas <span style={{fontWeight: '900'}}>entre 30 y 65 años</span>, especialmente aquellas que puedan tener uno o varios factores de riesgo cardiovascular y <span style={{fontWeight: '900'}}>no estén diagnosticados previamente de ninguna enfermedad cardiovascular, diabetes tipo II o diabetes tipo I con microalbuminuria.</span></div>
            </div>
            <div className="HeartAgeCalculatorRightSide" ref={recalculateRef}>
                <div className="HeartAgeCalculatorRightSideTitleDiv">
                    <div className="HeartAgeCalculatorRightSideTitleFlex">
                        <img className="HeartAgeCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                        <div className="HeartAgeCalculatorRightSideTitleInnerDiv">
                            <div className="HeartAgeCalculatorRightSideUpTitle">Calculadora de Salud</div>
                            <h3 className="HeartAgeCalculatorRightSideTitle">Edad del corazón</h3>
                        </div>
                    </div>
                    <img className="HeartAgeCalculatorResultsTitleYMCLogo" src="https://static.yomecontrolo.cl/public/icons/YMC_Logo_NoText.png"/>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyTitle">Completa el cuestionario para conocer la edad de tu corazón y tu riesgo cardiovascular.</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1">1. Indícanos tu sexo</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1TileFlex">
                    <div className="HeartAgeCalculatorRightSideSurveyQ1Tile HeartAgeCalculatorRightSideSurveyQ1ActiveTile" id="MaleTile" onClick={() => GenderChange("M")}>
                        Hombre <br/>
                        <img className="HeartAgeCalculatorRightSideSurveyQ1TileIcon HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png" id="MaleTileIcon"/>
                    </div>
                    <div className="HeartAgeCalculatorRightSideSurveyQ1Tile" id="FemaleTile" onClick={() => GenderChange("F")}>
                        Mujer <br/>
                        <img className="HeartAgeCalculatorRightSideSurveyQ1TileIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleFemaleIconT.png" id="FemaleTileIcon"/>
                    </div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQuestionsFlex">
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">2. Indícanos tu edad</div>
                        <select defaultValue={Age} id="YMCAge" className='DoBTile HeartAgeCalculatorRightSideSurveyQ1AgeInput rounded-2' onChange={(e) => setAge(parseInt(e.target.value))}>
                        {Array.from({ length: 36 }, (_, i) => i+30).map((day) => (
                            <option key={day} value={parseInt(day)}>{day}</option>
                        ))}
                        </select>
                    </div>
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">3. ¿Fumas?</div>
                        <div className="HeartAgeCalculatorRightSideSurveyQ1TileFlex">
                            <div className="HeartAgeCalculatorRightSideSurveyQ1Tile HeartAgeCalculatorRightSideSurveyQ1ActiveTile" id="NoSmokeTile" onClick={() => SmokeChange("NO")}>
                                No
                            </div>
                            <div className="HeartAgeCalculatorRightSideSurveyQ1Tile" id="SmokeTile" onClick={() => SmokeChange("YES")}>
                                Sí
                            </div>
                        </div>
                    </div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1Flex">
                    <div className="HeartAgeCalculatorRightSideSurveyQ1">4. Indícanos tu presión arterial sistólica</div>
                    <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("PressureInfo")}>?</div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1SuggestionText InformationCollapsed" id="PressureInfo">Es la cifra superior (y más alta) en una medición de la presión arterial.</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                    <input
                        value={Sistolic}
                        className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                        style={{ border: validation["Blood"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                        id="YMCSistolic"
                        onChange={(e) => handleChange(e.target.value,setSistolic)}
                        placeholder="Ingresa tu presión sistólica" />
                    <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">mmHg</div>
                </div>
                {validation["Blood"] === "invalid" ?
                    <div className='BloodAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                : null }
                <div className="HeartAgeCalculatorRightSideSurveyQ1Flex">
                    <div className="HeartAgeCalculatorRightSideSurveyQ1">5. Indícanos tu colesterol total (venoso) </div>
                    <div className="ExamChoiceByGenderChoiceExamInfo" onClick={() => toggleAdditional("CholesterolSuggestion")}>?</div>
                </div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1SuggestionText InformationCollapsed" id="CholesterolSuggestion">¿No sabes tu Colesterol Total? Te damos una <span className='HeartAgeCalculatorRightSideSurveyQ1SuggestionLink' onClick={() => {setToLipidic(true); localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041"));}}>orden médica gratuita</span> con los exámenes necesarios, para que te pongas al día con tu corazón.</div>
                <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                    <input
                        value={Choles}
                        className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                        style={{ border: validation["Choles"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                        id="YMCCholes"
                        onChange={(e) => handleChange(e.target.value,setCholes)}
                        placeholder="Ingresa tu colesterol total" />
                    <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">mg/dL</div>
                </div>
                {validation["Choles"] === "invalid" ?
                    <div className='CholesAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                : null }
                <div className="HeartAgeCalculatorRightSideSurveyQuestionsFlex">
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">6. Ingresa tu peso </div>
                        <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                            <input
                                value={Weight}
                                className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                                style={{ border: validation["Weight"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                                id="YMCWeight"
                                onChange={(e) => handleChange(e.target.value,setWeight)}
                                placeholder="Ingresa tu peso" />
                            <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">Kg</div>
                        </div>
                        {validation["Weight"] === "invalid" ?
                            <div className='WeightAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                        : null }
                    </div>
                    <div className="HeartAgeCalculatorRightSideSurveyQuestion">
                        <div className="HeartAgeCalculatorRightSideSurveyQ1">7. Ingresa tu altura </div>
                        <div className="HeartAgeCalculatorRightSideSurveyQ1MeasureFlex">
                            <input
                                value={Height}
                                className="HeartAgeCalculatorRightSideSurveyQ4InputTile rounded-1"
                                style={{ border: validation["Height"] === "invalid" ? "red solid 1px" : "#cff9ff solid 1px" }}
                                id="YMCHeight"
                                onChange={(e) => handleChange(e.target.value,setHeight)}
                                placeholder="Ingresa tu altura" />
                            <div className="HeartAgeCalculatorRightSideSurveyQ4InputMeasure">cm</div>
                        </div>
                        {validation["Height"] === "invalid" ?
                            <div className='HeightAlert' style={{ color: "red", fontWeight: "500" }}> Input inválido o fuera de rango </div>
                        : null }
                    </div>
                </div>
                <div className='HeartAgeCalculatorRightSideSurveyQuestionsFlex'>
                    <div className="HeartAgeCalculatorRightSideSurveyQ1">8. ¿Cuando fue la última vez que te hiciste un chequeo preventivo?</div>
                    <div className="HeartAgeCalculatorRightSideSurveyTimeInput">
                        <select defaultValue="<1Año" className='DoBTile rounded-2' style={{ border: "#cff9ff solid 1px" }} onChange={(e) => setRecent(e.target.value)}>
                            <option key={"Never"} value={"Never"}>Nunca</option>
                            <option key={">1Año"} value={">1Año"}>&gt; 1 Año</option>
                            <option key={"<1Año"} value={"<1Año"}>&lt; 1 Año</option>
                        </select>
                    </div>
                </div>

                {HeartAgeCalculated ?
                    <div className="HeartAgeCalculatorRightSideSurveyCalculate" onClick={() => HandleSubmit()}>
                        <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Recalcular</div>
                        <div className="HeartAgeCalculatorResultsRightSideEmailIconDiv">
                            <img className="HeartAgeCalculatorResultsRightSideEmailIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                :
                TaskStart ?
                    <div className="HeartAgeCalculatorRightSideSurveyCalculate">
                        <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Calculando...</div>
                        <div className="HeartAgeCalculatorResultsRightSideEmailIconDiv">
                            <img className="HeartAgeCalculatorResultsRightSideEmailIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                :
                    <div className="HeartAgeCalculatorRightSideSurveyCalculate" onClick={() => HandleSubmit()}>
                        <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Calcular</div>
                        <div className="HeartAgeCalculatorResultsRightSideEmailIconDiv">
                            <img className="HeartAgeCalculatorResultsRightSideEmailIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteCalculatorIcon.png"/>
                        </div>
                    </div>
                }
            </div>
        </div>

        {Calculated ?
        <div className="HeartAgeCalculatorInnerDiv" ref={calculatedRef}>
            <div className="HeartAgeCalculatorResultsDiv">
                <div className="HeartAgeCalculatorResultsTitleDiv" style={{backgroundColor: Risk.heartAge > TmpAge ? "#CCCCFF" : "#CCCCFF"}}>
                <div className="HeartAgeCalculatorRightSideTitleFlex">
                        <img className="HeartAgeCalculatorRightSideTitleIcon" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleLogo.png"/>
                        <div className="HeartAgeCalculatorRightSideTitleInnerDiv">
                            <div className="HeartAgeCalculatorRightSideUpTitle">Calculadora de Salud</div>
                            <div className="HeartAgeCalculatorRightSideTitle">Resultados</div>
                        </div>
                    </div>
                    <img className="HeartAgeCalculatorResultsTitleYMCLogo" src="https://static.yomecontrolo.cl/public/icons/YMC_Logo_NoText.png"/>
                </div>
                {TmpAge < 40 ?
                <>
                <div className="HeartAgeCalculatorResultsContent">
                    <div className="HeartAgeCalculatorResultsLeftSide" style={{border:'0px'}}>
                    <div className="HeartAgeCalculatorCardioResults">
                    <div className="HeartAgeCalculatorCardioResultsUpTitle">Si mantienes tu estilo de vida actual...</div>
                    <div className="HeartAgeCalculatorCardioResultsTitle">¿Qué pasaría con tu riesgo cardiovascular en 10 años?</div>
                    <div className="HeartAgeCalculatorCardioResultsColorBlock" style={{ backgroundColor: Risk10.cardioRisk === 0 ? "#8BC53F" : Risk10.cardioRisk <= 3 ? "#FFCD29" : "#FF4F53", color: Risk10.cardioRisk >= 4 ? "white": "" }}>
                        <div className="HeartAgeCalculatorCardioResultsColorBlockUpperText">
                            <div className="HeartAgeCalculatorCardioResultsColorBlockUpperTextType">Riesgo {Risk10.cardioRiskCat}</div>
                            <img className="HeartAgeCalculatorCardioResultsColorBlockUpperTextTypeHeartIcon" src="https://static.yomecontrolo.cl/public/icons/DarkPurpleCardioHeartTrasparent.png"/>
                        </div>
                        {Risk10.cardioRisk === 0 ?
                        <div className="HeartAgeCalculatorCardioResultsColorBlockLowerText">Cuando cumplas {TmpAge+10} años (manteniendo tu estilo de vida y cuidado actual) tu corazón acumulará <span style={{fontWeight: '900'}}>{Risk10.heartAge-TmpAge-10} años de envejecimiento prematuro</span>, teniendo la misma probabilidad de morir por un episodio cardiovascular que una persona de {Risk10.heartAge} años sana.</div>
                            : Risk10.cardioRisk <= 3 ? 
                        <div className="HeartAgeCalculatorCardioResultsColorBlockLowerText">Cuando cumplas {TmpAge+10} años (manteniendo tu estilo de vida y cuidado actual) tu corazón acumulará <span style={{fontWeight: '900'}}>{Risk10.heartAge-TmpAge-10} años de envejecimiento prematuro</span>, teniendo la misma probabilidad de morir por un episodio cardiovascular que una persona de {Risk10.heartAge} años sana.</div> 
                            : 
                        <div className="HeartAgeCalculatorCardioResultsColorBlockLowerText" style={{color: 'white'}}>Cuando cumplas {TmpAge+10} años (manteniendo tu estilo de vida y cuidado actual) tu corazón acumulará <span style={{fontWeight: '900'}}>{Risk10.heartAge-TmpAge-10} años de envejecimiento prematuro</span>, teniendo la misma probabilidad de morir por un episodio cardiovascular que una persona de {Risk10.heartAge} años sana.</div>
                        }
                    </div>
                    <div className="HeartAgeCalculatorCardioResultsHorizontalMeterContainer">

                        <div className="HeartAgeCalculatorCardioResultsHorizontalMeter" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontal.png')",backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat'}}>
                            {Risk10.cardioRisk === 0 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_LowRiskFace.png" style={{left:"-4%"}} />
                            : Risk10.cardioRisk === 1 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{left:"19%"}} />
                            : Risk10.cardioRisk === 2 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{left:"0",right:"0",marginLeft:"auto",marginRight:"auto"}} />
                            : Risk10.cardioRisk === 3 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{right:"19%"}} />
                            :
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_HighRiskFace.png" style={{right:"-4%"}} />
}
                        </div>
                    </div>
                </div>
                <div className='HeartAgeCalculatorCardioResultsBottomText'>Este riesgo fue calculado utilizando la metodología SCORE, la cual es una herramienta de evaluación de riesgos fácil de usar, desarrollada para ayudar a los profesionales de la salud a evaluar el riesgo de que su paciente muera por causas cardiovasculares (cardíacas y circulatorias), como infarto de miocardio (ataque cardíaco), insuficiencia cardíaca (incapacidad del corazón para bombear sangre) o accidente cerebrovascular (bloqueo repentino de un vaso sanguíneo que transporta oxígeno y nutrientes) a lo largo de un período de diez años. Incluye múltiples factores de riesgo, la mayoría de los cuales se pueden abordar, como la presión arterial, el colesterol en sangre (nivel de grasas en la sangre) y el hábito de fumar. También incluye factores de riesgo no modificables como la edad y el sexo. SCORE le muestra cómo estos factores de riesgo interactúan para calcular su riesgo general y destaca qué factores de riesgo se pueden tratar para reducirlo.</div>
                <div className='HeartAgeCalculatorCardioResultsBottomText'>La información y las recomendaciones que aparecen en esta calculadora son adecuadas en la mayoría de los casos, pero no reemplazan el diagnóstico médico. Para obtener información específica relacionada con su condición personal, consulte a su médico.</div>
                    </div>
                    <div className="HeartAgeCalculatorResultsRightSide">
                        <div className="HeartAgeCalculatorResultsRightSidePackBlock">
                            <img className="HeartAgeCalculatorResultsRightSidePackBlockExamIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockUpTitle">Campaña mes del corazón 2024</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockTitle">¡Hazlo por ti! Para que vivas más y mejor.</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockSubtitle">Durante el mes del corazón,  descarga tu orden médica gratuita con los exámenes necesarios para que te realices un “Chequeo Cardiovascular Preventivo” y te pongas al día con tu corazón.</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPack">
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/PM00YMC041.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}><div className="ExamChoiceByGenderPackBoxImageGradient"></div></div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackTitle">Chequeo cardiovascular preventivo</div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">Exámenes:</div>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">({packslist["PM00YMC041"].exams.length + packslist["PM00YMC041"].examsPro.length})</div>
                                </div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIconsFlex">
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleMicroscopeIconTr.png"/>
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleCardioHeartTrasparent.png"/>
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleDNAIconTr.png" style={{ paddingInline:"1px" }}/>
                                    </div>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFree">Gratis</div>
                                </div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonFlex" onClick={() => {setToLipidic(true); localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041"));}}>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonText">Ver Detalle</div>
                                    <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </>
                :
                <>
                <div className="HeartAgeCalculatorResultsContent">
                    {Risk.heartAge > TmpAge ?
                    <>
                    <div className="HeartAgeCalculatorResultsLeftSide">
                        <div className="HeartAgeCalculatorResultsLeftSideUpTitle" /*style={{color:"#FF4F53"}}*/>Edad del Corazón</div>
                        {Risk.cardioRisk <= 3 ?
                        <>
                        <div className="HeartAgeCalculatorResultsLeftSideTitle" /*style={{color:"#FF4F53"}}*/>¡Tu corazón es {Risk.heartAge - TmpAge} años mayor que tú!</div>
                        <div className="HeartAgeCalculatorResultsLeftSideDownTitle"></div>
                        </>
                        :
                        <>
                        <div className="HeartAgeCalculatorResultsLeftSideTitle" /*style={{color:"#FF4F53"}}*/>¡Tu corazón es {Risk.heartAge - TmpAge} años mayor que tú!</div>
                        <div className="HeartAgeCalculatorResultsLeftSideDownTitle">Es importante que hables con tu médico sobre lo que esto significa y lo que puede hacer para reducir el riesgo de sufrir una enfermedad cardiovascular.</div>
                        </>
}
                        {/*
                        <div className="HeartAgeCalculatorResultsLeftSideMeter">
                            <div className="HeartAgeCalculatorResultsLeftSideActualResult">
                                <img className="HeartAgeCalculatorResultsTitleIcon2" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png"/>
                                <div className="HeartAgeCalculatorResultsLeftSideActualResultValue">{Risk} Años</div>
                            </div>
                        </div>
                        */}
                        <div className="HeartAgeCalculatorResultsLeftSideActualResultImage" style={{backgroundImage: Risk.cardioRisk === 0 ? "url('https://static.yomecontrolo.cl/public/img/calculator/BigHeartLowRiskTp.png')" : Risk.cardioRisk <= 3 ? "url('https://static.yomecontrolo.cl/public/img/calculator/BigHeartMidRiskTp.png')" : "url('https://static.yomecontrolo.cl/public/img/calculator/BigHeartHighRiskTp.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                            <div className="HeartAgeCalculatorResultsLeftSideActualResultValue">{Risk.heartAge}</div>
                        </div>
                        <div className="HeartAgeCalculatorResultsLeftSideSubtitle" style={{ backgroundColor: Risk.cardioRisk === 0 ? "#8BC53F" : Risk.cardioRisk <= 3 ? "#FFCD29" : "#FF4F53", color: Risk.cardioRisk >= 4 ? "white": "" }}>Esto significa que hoy tienes la misma probabilidad de morir por un episodio cardiovascular que una persona sana de {Risk.heartAge} años.</div>

                    </div>
                    <div className="HeartAgeCalculatorResultsRightSide">
                        <div className="HeartAgeCalculatorResultsRightSideInnerDiv">
                        {WOSmoke < 1 && RegSistolic < 1 && RegCholes ?
                            null :
                            <>
                            <div className="HeartAgeCalculatorResultsRightSideUpTitleFlex">
                                <img className="HeartAgeCalculatorResultsRightSideUpTitleHeartIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleCardioHeartTrasparent.png"/>
                                <div className="HeartAgeCalculatorResultsRightSideUpTitle">¿Qué pasaría sí?</div>
                            </div>
                            {WOSmoke > 0 ?
                            <div className="HeartAgeCalculatorResultsRightSideWhatIfBlock">
                                <div className="HeartAgeCalculatorResultsRightSideWhatIfScenario">Dejar de fumar</div>
                                <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence">Ganarías {Risk.heartAge - WOSmoke} años</div>
                            </div>
                            : null}
                            {RegSistolic > 0 ?
                            <div className="HeartAgeCalculatorResultsRightSideWhatIfBlock">
                                <div className="HeartAgeCalculatorResultsRightSideWhatIfScenario">Regula tu presión arterial a 120 mmHg</div>
                                <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence">Ganarías {Risk.heartAge - RegSistolic} años</div>
                            </div>
                            : null}
                            {RegCholes > 0 ?
                            <div className="HeartAgeCalculatorResultsRightSideWhatIfBlock">
                                <div className="HeartAgeCalculatorResultsRightSideWhatIfScenario">Regula tu colesterol total a 154 mg/dL</div>
                                <div className="HeartAgeCalculatorResultsRightSideWhatIfConsequence">Ganarías {Risk.heartAge - RegCholes} años</div>
                            </div>
                            : null}
                            </>
}
                        <div className="HeartAgeCalculatorResultsRightSideSubtitle">Modifica los parámetros y compara los resultados</div>
                        <div className="HeartAgeCalculatorResultsRightSideRouteButton" onClick={() => executeScroll2()}>Recalcular</div>
                        {/*
                        <div className="HeartAgeCalculatorResultsRightSideEmailBlock">
                            <div className="HeartAgeCalculatorResultsRightSideEmailBlockEmailText">Correo electrónico*</div>
                            <input
                                value={Email}
                                className="HeartAgeCalculatorResultsRightSideEmailBlockInputTile"
                                style={{ border: validation2["Email"] === "invalid" ? "red solid 1px" : "#CFF9FF solid 1px" }}
                                id="YMCEmail"
                                type="email"
                                maxLength={60}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Ingresa Tu Mail" />
                            {Object.values(validation2).indexOf('invalid') > -1 ?
                            <div className="HeartAgeCalculatorResultsRightSideEmailBlockInvalid">Por favor ingresar dirección de correo válida.</div>
                            : null}
                            <div className="HeartAgeCalculatorRightSideSurveyCalculate" onClick={() => ValidateEmail()}>
                                <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Recibir PDF</div>
                                <div className="HeartAgeCalculatorResultsRightSideEmailIconDiv">
                                    <img className="HeartAgeCalculatorResultsRightSideEmailIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteEmailIcon.png"/>
                                </div>
                            </div>
                        </div>
*/}                     </div>
                        <div className="HeartAgeCalculatorResultsRightSidePackBlock">
                            <img className="HeartAgeCalculatorResultsRightSidePackBlockExamIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockUpTitle">Campaña mes del corazón 2024</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockTitle">¡Hazlo por ti! Para que vivas más y mejor.</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockSubtitle">Durante el mes del corazón,  descarga tu orden médica gratuita con los exámenes necesarios para que te realices un “Chequeo Cardiovascular Preventivo” y te pongas al día con tu corazón.</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPack">
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/PM00YMC041.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}><div className="ExamChoiceByGenderPackBoxImageGradient"></div></div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackTitle">Chequeo cardiovascular preventivo</div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">Exámenes:</div>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">({packslist["PM00YMC041"].exams.length + packslist["PM00YMC041"].examsPro.length})</div>
                                </div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIconsFlex">
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleMicroscopeIconTr.png"/>
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleCardioHeartTrasparent.png"/>
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleDNAIconTr.png" style={{ paddingInline:"1px" }}/>
                                    </div>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFree">Gratis</div>
                                </div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonFlex" onClick={() => {setToLipidic(true); localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041"));}}>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonText">Ver Detalle</div>
                                    <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                                </div>
                            </div>
                        </div>
                    </div> 
                    </>
                    : 
                    <>
                    <div className="HeartAgeCalculatorResultsLeftSide">
                        <div className="HeartAgeCalculatorResultsLeftSideUpTitle" /*style={{color:"#FF4F53"}}*/>Edad del Corazón</div>
                        <div className="HeartAgeCalculatorResultsLeftSideTitle" /*style={{color:"#FF4F53"}}*/>¡Sigue así, vas a la par con tu corazón!</div>
                        <div className="HeartAgeCalculatorResultsLeftSideDownTitle"></div>
                        {/*
                        <div className="HeartAgeCalculatorResultsLeftSideMeterLow">
                            <div className="HeartAgeCalculatorResultsLeftSideActualResult">
                                <img className="HeartAgeCalculatorResultsTitleIcon2" src="https://static.yomecontrolo.cl/public/icons/HealthCalculatorPurpleMaleIcon.png"/>
                                <div className="HeartAgeCalculatorResultsLeftSideActualResultValue">{Risk} Años</div>
                            </div>
                        </div> */}
                        <div className="HeartAgeCalculatorResultsLeftSideActualResultImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/BigHeartLowRiskTp.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}>
                            <div className="HeartAgeCalculatorResultsLeftSideActualResultValue">{Risk.heartAge}</div>
                        </div>
                    </div>
                    <div className="HeartAgeCalculatorResultsRightSide">
                        <div className="HeartAgeCalculatorResultsRightSideInnerDiv">
                            <div className="HeartAgeCalculatorResultsRightSideUpTitle2">Estás en un rango saludable para tu edad actual</div>
                            <div className="HeartAgeCalculatorResultsRightSideSubtitle">Recuerda la importancia de llevar un estilo de vida saludable, cuidar tu alimentación y realizar ejercicio físico de manera regular adaptado a tu condición física.</div>
                            <div className="HeartAgeCalculatorResultsRightSideSubtitle">Modifica los parámetros y compara los resultados</div>
                            <div className="HeartAgeCalculatorResultsRightSideRouteButton" onClick={() => executeScroll2()}>Recalcular</div>
                        {/*
                        <div className="HeartAgeCalculatorResultsRightSideEmailBlock">
                            <div className="HeartAgeCalculatorResultsRightSideEmailBlockEmailText">Email</div>
                            <input
                                value={Email}
                                className="HeartAgeCalculatorResultsRightSideEmailBlockInputTile"
                                style={{ border: validation2["Email"] === "invalid" ? "red solid 1px" : "#CCCCFF solid 2px" }}
                                id="YMCEmail"
                                type="email"
                                maxLength={60}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email" />
                            {Object.values(validation2).indexOf('invalid') > -1 ?
                            <div className="HeartAgeCalculatorResultsRightSideEmailBlockInvalid">Por ingresar dirección de correo válida.</div>
                            : null}
                            <div className="HeartAgeCalculatorRightSideSurveyCalculate" onClick={() => ValidateEmail()}>
                                <div className="HeartAgeCalculatorResultsRightSideEmailButtonText">Recibir PDF</div>
                                <div className="HeartAgeCalculatorResultsRightSideEmailIconDiv">
                                    <img className="HeartAgeCalculatorResultsRightSideEmailIcon" src="https://static.yomecontrolo.cl/public/icons/WhiteEmailIcon.png"/>
                                </div>
                            </div>
                        </div>
                         */}
                        </div>
                        <div className="HeartAgeCalculatorResultsRightSidePackBlock">
                            <img className="HeartAgeCalculatorResultsRightSidePackBlockExamIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockUpTitle">Campaña mes del corazón 2024</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockTitle">¡Hazlo por ti! Para que vivas más y mejor.</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockSubtitle">Durante el mes del corazón,  descarga tu orden médica gratuita con los exámenes necesarios para que te realices un “Chequeo Cardiovascular Preventivo” y te pongas al día con tu corazón.</div>
                            <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPack">
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackImage" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/packs/PM00YMC041.png')",backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat'}}><div className="ExamChoiceByGenderPackBoxImageGradient"></div></div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackTitle">Chequeo cardiovascular preventivo</div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">Exámenes:</div>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackSubtitle">({packslist["PM00YMC041"].exams.length + packslist["PM00YMC041"].examsPro.length})</div>
                                </div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex">
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIconsFlex">
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleMicroscopeIconTr.png"/>
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleCardioHeartTrasparent.png"/>
                                        <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleDNAIconTr.png" style={{ paddingInline:"1px" }}/>
                                    </div>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFree">Gratis</div>
                                </div>
                                <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonFlex" onClick={() => {setToLipidic(true); localStorage.setItem("VisualizePack",JSON.stringify("PM00YMC041"));}}>
                                    <div className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonText">Ver Detalle</div>
                                    <img className="HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonIcon" src="https://static.yomecontrolo.cl/public/icons/PurpleExamIcon.png"/>
                                </div>
                            </div>
                        </div>
                    </div> 
                    </>}
                </div>
                <div className="HeartAgeCalculatorCardioResults">
                    <div className="HeartAgeCalculatorCardioResultsUpTitle"></div>
                    <div className="HeartAgeCalculatorCardioResultsTitle">Riesgo cardiovascular actual</div>
                    <div className="HeartAgeCalculatorCardioResultsColorBlock" style={{ backgroundColor: Risk.cardioRisk === 0 ? "#8BC53F" : Risk.cardioRisk <= 3 ? "#FFCD29" : "#FF4F53", color: Risk.cardioRisk >= 4 ? "white": ""}}>
                        <div className="HeartAgeCalculatorCardioResultsColorBlockUpperText1">
                            <div className="HeartAgeCalculatorCardioResultsColorBlockUpperTextType">Riesgo {Risk.cardioRiskCat}</div>
                            <img className="HeartAgeCalculatorCardioResultsColorBlockUpperTextTypeHeartIcon" src="https://static.yomecontrolo.cl/public/icons/DarkPurpleCardioHeartTrasparent.png"/>
                        </div>
                    </div>
                    <div className="HeartAgeCalculatorCardioResultsHorizontalMeterContainer">

                        <div className="HeartAgeCalculatorCardioResultsHorizontalMeter" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontal.png')",backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat'}}>
                            {Risk.cardioRisk === 0 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_LowRiskFace.png" style={{left:"-4%"}} />
                            : Risk.cardioRisk === 1 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{left:"19%"}} />
                            : Risk.cardioRisk === 2 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{left:"0",right:"0",marginLeft:"auto",marginRight:"auto"}} />
                            : Risk.cardioRisk === 3 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{right:"19%"}} />
                            :
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_HighRiskFace.png" style={{right:"-4%"}} />
}
                        </div>
                    </div>
                </div>
                <div className="HeartAgeCalculatorCardioResults">
                    <div className="HeartAgeCalculatorCardioResultsUpTitle">Si mantienes tu estilo de vida actual...</div>
                    <div className="HeartAgeCalculatorCardioResultsTitle">¿Qué pasaría con tu riesgo cardiovascular en 10 años?</div>
                    <div className="HeartAgeCalculatorCardioResultsColorBlock" style={{ backgroundColor: Risk10.cardioRisk === 0 ? "#8BC53F" : Risk10.cardioRisk <= 3 ? "#FFCD29" : "#FF4F53", color: Risk10.cardioRisk >= 4 ? "white": "" }}>
                        <div className="HeartAgeCalculatorCardioResultsColorBlockUpperText">
                            <div className="HeartAgeCalculatorCardioResultsColorBlockUpperTextType">Riesgo {Risk10.cardioRiskCat}</div>
                            <img className="HeartAgeCalculatorCardioResultsColorBlockUpperTextTypeHeartIcon" src="https://static.yomecontrolo.cl/public/icons/DarkPurpleCardioHeartTrasparent.png"/>
                        </div>
                        {Risk10.cardioRisk === 0 ?
                        <div className="HeartAgeCalculatorCardioResultsColorBlockLowerText">Cuando cumplas {TmpAge+10} años (manteniendo tu estilo de vida y cuidado actual) tu corazón acumulará <span style={{fontWeight: '900'}}>{Risk10.heartAge-TmpAge-10} años de envejecimiento prematuro</span>, teniendo la misma probabilidad de morir por un episodio cardiovascular que una persona de {Risk10.heartAge} años sana.</div>
                            : Risk10.cardioRisk <= 3 ? 
                        <div className="HeartAgeCalculatorCardioResultsColorBlockLowerText">Cuando cumplas {TmpAge+10} años (manteniendo tu estilo de vida y cuidado actual) tu corazón acumulará <span style={{fontWeight: '900'}}>{Risk10.heartAge-TmpAge-10} años de envejecimiento prematuro</span>, teniendo la misma probabilidad de morir por un episodio cardiovascular que una persona de {Risk10.heartAge} años sana.</div> 
                            : 
                        <div className="HeartAgeCalculatorCardioResultsColorBlockLowerText" style={{color: 'white'}}>Cuando cumplas {TmpAge+10} años (manteniendo tu estilo de vida y cuidado actual) tu corazón acumulará <span style={{fontWeight: '900'}}>{Risk10.heartAge-TmpAge-10} años de envejecimiento prematuro</span>, teniendo la misma probabilidad de morir por un episodio cardiovascular que una persona de {Risk10.heartAge} años sana.</div>
                        }
                    </div>
                    <div className="HeartAgeCalculatorCardioResultsHorizontalMeterContainer">

                        <div className="HeartAgeCalculatorCardioResultsHorizontalMeter" style={{backgroundImage:"url('https://static.yomecontrolo.cl/public/img/calculator/RiskMeterHorizontal.png')",backgroundPosition: 'center',backgroundSize: 'contain',backgroundRepeat: 'no-repeat'}}>
                            {Risk10.cardioRisk === 0 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_LowRiskFace.png" style={{left:"-4%"}} />
                            : Risk10.cardioRisk === 1 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{left:"19%"}} />
                            : Risk10.cardioRisk === 2 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{left:"0",right:"0",marginLeft:"auto",marginRight:"auto"}} />
                            : Risk10.cardioRisk === 3 ?
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_MidRiskFace.png" style={{right:"19%"}} />
                            :
                            <img className="HeartAgeCalculatorCardioResultsHorizontalMeterFace" src="https://static.yomecontrolo.cl/public/img/calculator/RiskMeter_HighRiskFace.png" style={{right:"-4%"}} />
}
                        </div>
                    </div>
                </div>
                <div className='HeartAgeCalculatorCardioResultsBottomText'>Este riesgo fue calculado utilizando la metodología SCORE, la cual es una herramienta de evaluación de riesgos fácil de usar, desarrollada para ayudar a los profesionales de la salud a evaluar el riesgo de que su paciente muera por causas cardiovasculares (cardíacas y circulatorias), como infarto de miocardio (ataque cardíaco), insuficiencia cardíaca (incapacidad del corazón para bombear sangre) o accidente cerebrovascular (bloqueo repentino de un vaso sanguíneo que transporta oxígeno y nutrientes) a lo largo de un período de diez años. Incluye múltiples factores de riesgo, la mayoría de los cuales se pueden abordar, como la presión arterial, el colesterol en sangre (nivel de grasas en la sangre) y el hábito de fumar. También incluye factores de riesgo no modificables como la edad y el sexo. SCORE le muestra cómo estos factores de riesgo interactúan para calcular su riesgo general y destaca qué factores de riesgo se pueden tratar para reducirlo.</div>
                <div className='HeartAgeCalculatorCardioResultsBottomText'>La información y las recomendaciones que aparecen en esta calculadora son adecuadas en la mayoría de los casos, pero no reemplazan el diagnóstico médico. Para obtener información específica relacionada con su condición personal, consulte a su médico.</div>
                </>}
            </div>
        </div>
        : null }
        <div className="HeartAgeCalculatorInnerDiv" style={{display: 'block'}}>
            <div className="HeartAgeCalculatorBibliographyFlex" onClick={() => displayBiblio()}>
                <div className="HeartAgeCalculatorBibliography">Referencias Bibliográficas&ensp;</div>
                {ArrowUp ?
                <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className='HeartAgeCalculatorBibliographyArrow fa-rotate-180'
                    />
                :
                <FontAwesomeIcon 
                    icon={faAngleDown} 
                    className='HeartAgeCalculatorBibliographyArrow'
                    />
                }
            </div>
            <div id='BiblioContent' className="HeartAgeCalculatorBibliographyContent HeartAgeCalculatorBibliographyContentHidden">
                1.- Cuende JI, Cuende N, Calaveras-Lagartos J. How to calculate vascular age with the SCORE project scales: a new method of cardiovascular risk evaluation. Eur Heart J. 2010 Oct;31(19):2351-8. doi: 10.1093/eurheartj/ehq205. Epub 2010 Jun 28. PMID: 20584778.
                <br/>
                2.- María Teresa Lira, Estratificación de riesgo cardiovascular: conceptos, análisis crítico, desafíos e historia de su desarrollo en Chile, Revista Médica Clínica Las Condes. <a href='https://www.sciencedirect.com/science/article/pii/S0716864022001055'>Link externo</a>
            </div>
        </div>
        <Newsletter/>
    </div>
  );
}

export default HeartAgeCalculator;